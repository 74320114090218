import PropTypes from 'prop-types';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import facebook from '../../assets/images/facebooks.svg';
import tweeter from '../../assets/images/tweeter.svg';
import copyLink from '../../assets/images/copyLink.svg';
import email from '../../assets/images/email.svg';
import closeImg from '../../assets/images/closeButton.svg';
import styles from './sass/Modal.module.scss';

function ShareModal({
  onCancelClick, title
}) {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      });
  };

  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <h1 className={styles.bold}>{t(title)}</h1>
        <button className={styles.close} type="button" onClick={onCancelClick}>
          <img src={closeImg} alt="X" />
        </button>
      </div>
      <div className={styles.socialContainer}>
        <div className={styles.socialSubContainer}>
          <FacebookShareButton
            title=""
            url={window.location.href}
          >
            <img src={facebook} className={styles.button} alt="" />
          </FacebookShareButton>
          <p>{t('facebook')}</p>
        </div>
        <div className={styles.socialSubContainer}>
          <TwitterShareButton
            title=""
            url={window.location.href}
            via="sustieio"
          >
            <img src={tweeter} className={styles.button} alt="" />
            <p>{t('twitter')}</p>
          </TwitterShareButton>
        </div>
        <div className={styles.socialSubContainer}>
          <button
            type="button"
            className={styles.button}
            onClick={() => handleCopyClick(window.location.pathname)}
          >
            <img src={copyLink} alt="" />
          </button>
          <p>{isCopied ? t('linkCopied') : t('copyLink')}</p>
        </div>
        <div className={styles.socialSubContainer}>
          <EmailShareButton
            onClick={() => {}}
            openShareDialogOnClick
            url=""
            subject=""
            body=""
          >
            <img src={email} className={styles.button} alt="" />
          </EmailShareButton>
          <p>{t('emailLabel')}</p>
        </div>
      </div>
    </div>
  );
}

ShareModal.propTypes = {
  onCancelClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

ShareModal.defaultProps = {
  onCancelClick: () => {},
};

export default ShareModal;
