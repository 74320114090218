import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ActivateView from './ActivateView';
import { userActivateAsync } from '../../store/resendActivation/slice';
import Loader from '../../components/Loader/Loader';

function Activate() {
  const dispatch = useDispatch();
  const { uid, token } = useParams();
  const [isActivated, setIsActivated] = useState(true);
  const activationData = useSelector((storeState) => storeState.resendActivation);

  useEffect(() => {
    dispatch(userActivateAsync({ uid, token }))
      .then((res) => {
        if (res.error) {
          setIsActivated(false);
        } else {
          setIsActivated(true);
        }
      });
  }, []);
  return (
    activationData.isLoading
      ? <Loader />
      : <ActivateView isActivated={isActivated} />
  );
}

export default Activate;
