export default {
  accountLogin: 'Log in to your account',
  welcomeBack: 'Welcome back! Please enter your details.',
  enterEmail: 'Enter your email',
  firstName: 'First Name',
  lastName: 'Last Name',
  userName: 'Username',
  enterPassword: 'Enter your password',
  enterFirstName: 'Enter your first name',
  enterLastName: 'Enter your last name',
  enterUserName: 'Enter your username',
  remember: 'Remember for 30 days',
  forgotPassword: 'Forgot password',
  continueWithEmail: 'Continue with email',
  or: 'OR',
  dontHaveAccount: 'Don’t have an account?',
  signUp: 'Sign up',
  continueWithGoogle: 'Continue with Google',
  emailLabel: 'Email',
  resetPassword: 'Reset password',
  passReset: 'Password reset',
  forgotQuestion: 'Forgot password?',
  noWorries: 'No worries, we’ll send you reset instructions.',
  backToLogin: 'Back to log in',
  hintText: 'This is a hint text to help user.',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  updatePassword: 'Update Password',
  emailCheck: 'Check your email',
  sentReset: 'We sent a password reset link to',
  emailApp: 'Open email app',
  didntReceive: 'Didn’t receive the email?',
  clickToResend: 'Click to resend',
  setNewPassword: 'Set new password',
  passwordRules: 'Your new password must be different to previously used passwords.',
  passwordLength: 'Must be at least 8 characters.',
  newPasswordLength: 'Your new password must be at least 8 characters.',
  hasBeenReset: 'Your password has been successfully reset. Click below to log in magically.',
  pleaseEnterCurrent: 'Please, enter your current password to be able to change it.',
  continue: 'Continue',
  next: 'Next',
  back: 'Back',
  cancel: 'Cancel',
  delete: 'Delete',
  save: 'Save',
  male: 'Male',
  female: 'Female',
  myDetails: 'My details',
  myStyle: 'My Style',
  home: 'Home',
  wishlist: 'Wishlist',
  feedback: 'Feedback',
  newPassword: 'New password',
  currentPassword: 'Current password',
  confirmNew: 'Confirm new password',
  under: 'Under',
  between: 'Between',
  over: 'Over',
  bottoms: 'Bottoms',
  outerwear: 'Outerwear',
  allInOnes: 'All in ones',
  sleepwear: 'Sleepwear',
  workOut: 'Work Out',
  tops: 'Tops',
  dresses: 'Dresses',
  footwear: 'Footwear',
  productSets: 'Product sets',
  underwear: 'Underwear',
  chooseAsManyAsYouWant: 'Choose as many as you want',
  wear: 'Wear',
  style: 'Style',
  brands: 'Brands',
  answers: 'Answers',
  update: 'Update',
  whatYouWear: 'What do you wear?',
  whatClothing: 'What type of clothing you shop online?',
  whatDescribes: 'What best describes your style?',
  everyDayWear: 'Everyday wear',
  vintage: 'Vintage',
  grunge: 'Grunge',
  bohemian: 'Bohemian',
  officeWear: 'Office wear',
  dateNight: 'Date Night',
  activeWear: 'Active wear',
  maximalist: 'Maximalist',
  scandi: 'Scandi',
  streetwear: 'Streetwear',
  createAccount: 'Create Account',
  yourName: 'Your Name',
  yourGender: 'Your Gender',
  yourAge: 'Your Age',
  yourStyle: 'Your Style',
  pleaseCreateAccount: 'Please provide your name and email',
  whatIsYourName: 'What is your name?',
  describeYourGenderStyle: 'How would you describe your gender style?',
  whatAgeGroup: 'What age group are you in?',
  whatDoYouWear: 'What do you wear?',
  whatTypeOfClosing: 'What type of clothing you shop online?',
  describesYourStyle: 'What best describes your style?',
  searchYourWishList: 'Search your wishlist',

  settingMyDetails: {
    firstName: 'First name',
    lastName: 'Last name',
    username: 'Username',
    email: 'Email address',
    country: 'Country',
    gender: 'Gender',
    age: 'Age',
    save: 'Save',
    cancel: 'Cancel',
  },

  dragAndDropFiles: {
    clickToUpload: 'Click to upload',
    orDragAndDrop: 'or drag and drop',
    terms: 'SVG, PNG, JPG or GIF (max. 500x500px)',
  },

  noteToSelf: 'Note to self',
  addPrivateNote: 'Add a private note to remember your ideas',
  editYourWishlist: 'Edit your wishlist',
  name: 'Name',
  description: 'Description',
  whatIsYourWishlist: 'What is your wishlist about?',
  deleteWishlist: 'Delete wishlist',
  deleteItem: 'Delete wishlist item',
  shureDeleteWishlist: 'Are you shure you want to delete this wishlist?',
  shureDeleteItem: 'Are you shure you want to delete this item?',
  actionCantBeUndone: 'This action cannot be undone.',
  launch: 'Launch',
  goal: 'Goal',
  community: 'Community',
  chromeExtension: 'Chrome Extension',
  weMakeSustainable: `We make sustainable shopping online what it should be.
  Simple, honest and fun. You're the shopper.
  How you want to shop is up to you.
  We call it being Sustie!`,
  weBroughtTogether: `We have brought together entrepreneurs, technology leads,
  sustainability advocates, fashion and design enthusiasts
  and influencers to use the product as it goes live.`,
  theGoalIs: `The goal is to open the product to a select group
  to get some insight from people we really value
  and respect the views. This will allow us to improve
  on the product before our official launch next year!`,
  unverified: 'Unverified',

  emailRequired: 'Email is required',
  passwordRequired: 'Password is required',
  firstNameRequired: 'First name is required',
  lastNameRequired: 'Last name is required',
  userNameRequired: 'User name is required',
  containSustieDomain: 'Must contain sustie.io domain',
  passwordToLong: 'Password too long',
  passwordToShort: 'Must be at least 8 characters.',
  uppercaseCharacter: 'Must contain at least one uppercase character',
  specialCharacter: 'Must contain at least one special character',
  confirmPasswordRequired: 'Confirm Password Required',
  passwordDontMatch: 'Passwords does not match',
  shouldBeUnique: 'is already in use',

  logout: 'Log out',

  facebook: 'Facebook',
  twitter: 'Twitter',
  email: 'Email',
  copyLink: 'Copy link',
  linkCopied: 'Link copied',
  share: 'Share',

  userExists: 'this user already exist',
  noWishlistsYet: "You haven't saved any wishlists yet",
  noWishlistsFound: 'No such wishlists exist',

  verifyEmail: 'Verify email',
  accountNotVerified: 'Your account is not verified. Please verify your email by clicking the button below.',
  unverifiedEmail: 'Unverified email',
  sorryYouRegisteredByGoogle: 'Sorry, you were registered via Google, you need to use login via Google',
  success: 'Congratulation',
  failed: 'Unfortunately',
  nowYouVerified: 'Now you verified your account',
  somethingWentWrong: 'Something went wrong',
};
