import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

function PrivateRoute({ isActive }) {
  return isActive ? <Outlet /> : <Navigate to="/login" replace />;
}

PrivateRoute.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default PrivateRoute;
