import PropTypes from 'prop-types';
import cn from 'classnames';
import { useState } from 'react';
import styles from './scss/Input.module.scss';

function Input({
  type,
  name,
  placeholder,
  rightIcon,
  leftIcon,
  label,
  className,
  register,
  isError,
  iconShowPassword,
  rightIconError,
  defaultValue,
  disabled
}) {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  return (
    <div className={className}>
      {label && <label htmlFor={name} className={styles.label}>{label}</label>}
      <div
        className={cn(
          styles.input,
          focused && !isError ? styles.inputFieldFocus : null,
          isError ? styles.inputError : null
        )}
      >
        {leftIcon && <div className={cn(styles.icon, styles.left)}>{leftIcon}</div>}
        <input
          {...register(name)}
          type={type}
          className={cn(styles.input, styles.inputField)}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {iconShowPassword && <div className={cn(styles.icon, styles.right)}>{iconShowPassword}</div>}
        {rightIcon && <div className={cn(styles.icon, styles.left, styles.right)}>{rightIcon}</div>}
        {isError && rightIconError
            && <div className={cn(styles.icon, styles.left, styles.right)}>{rightIconError}</div>}
      </div>
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  iconShowPassword: PropTypes.node,
  register: PropTypes.func,
  isError: PropTypes.bool.isRequired,
  rightIcon: PropTypes.node,
  rightIconError: PropTypes.node,
  leftIcon: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  label: '',
  name: '',
  placeholder: '',
  defaultValue: '',
  rightIcon: null,
  rightIconError: null,
  iconShowPassword: null,
  leftIcon: null,
  register: () => {},
  className: '',
  disabled: false
};

export default Input;
