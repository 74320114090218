import { t } from 'i18next';
import PropTypes from 'prop-types';
import Layout from '../../layout/LayoutAuth/LayoutAuth';
import WearItem from '../../components/WearItem/WearItem';
import Loader from '../../components/Loader/Loader';
import { ButtonSpace } from '../../constants/general';
import styles from './sass/WearRegistration.module.scss';

function WearRegistrationView({
  userData, checked, handleCheck, onSubmit
}) {
  return (
    userData.isLoading ? (<Loader />) : (
      <Layout
        titleButtonNext={t('next')}
        titleButtonBack={t('back')}
        buttonsSpace={ButtonSpace['16']}
        withSidebar
        handleClick={onSubmit}
      >
        <p className={styles.text}>{t('chooseAsManyAsYouWant')}</p>
        <div className={styles.wearContainer}>
          {userData.closingType && userData.closingType.map((item) => (
            <WearItem
              key={item.id}
              handleClick={() => handleCheck(item.id)}
              text={item.name}
              checked={checked}
              isChecked={checked.map((toNumber) => parseInt(toNumber)).includes(item.id)}
              id={item.id}
            />
          ))}
        </div>
      </Layout>
    ));
}
WearRegistrationView.propTypes = {
  userData: PropTypes.shape({
    isLoading: PropTypes.bool,
    closingType: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};
export default WearRegistrationView;
