import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Layout from '../../layout/Layout/Layout';
import Modal from '../../layout/Modal/Modal';
import Button, { BUTTONS_TYPES } from '../../components/Button/Button';
import DropdownInput from '../../components/DropdownInput/DropdownInput';
import icon from '../../assets/images/shareIcon.svg';
import styles from './sass/WishlistItem.module.scss';
import DeleteWishlistModal from '../../components/DeleteWishlistModal/DeleteWishlistModal';
import ShareModal from '../../components/ShareModal/ShareModal';

function WishlistItemView({
  productPage,
  productLink,
  title,
  description,
  wishlists,
  wishlistIndex,
  dialog,
  showDialog,
  shareModal,
  showModal,
  onSaveClick,
  onCloseClick,
  itemImage,
  note,
  onItemSelect,
  onItemDelete,
}) {
  const { t } = useTranslation();
  const onClickHandler = () => {
    const elements = document.querySelectorAll(':hover');
    if (elements.length <= 5) {
      onCloseClick();
    }
  };
  const noteEdit = useRef();

  return (
    <Layout>
      <div className={styles.container} onClick={onClickHandler} role="button" tabIndex={0} onKeyDown={() => {}}>
        <div className={styles.card}>
          <img src={itemImage} alt="wishlist item" className={styles.img} />
          <div className={styles.controls}>
            <div className={styles.subContainer}>
              <div className={styles.head}>
                <a
                  href={productLink}
                  target="_blank"
                  className={styles.label}
                  rel="noopener noreferrer"
                >
                  {productPage}
                </a>
                <button className={styles.share} type="button" onClick={() => showModal(true)}>
                  <img src={icon} alt="share" />
                </button>
              </div>
              <div>
                <h1 className={styles.name}>{title}</h1>
                <p className={styles.description}>{description}</p>
              </div>
              <div>
                <DropdownInput
                  title={t('wishlist')}
                  items={wishlists}
                  value={wishlistIndex}
                  onItemSelect={onItemSelect}
                />
                <p className={styles.label}>{t('noteToSelf')}</p>
                <textarea
                  className={styles.area}
                  placeholder={t('addPrivateNote')}
                  defaultValue={note}
                  ref={noteEdit}
                />
              </div>
            </div>
            <div className={styles.head}>
              <Button
                title={t('delete')}
                className={styles.delete}
                typeButton={BUTTONS_TYPES.RED}
                onClick={() => showDialog(true)}
              />
              <Button
                title={t('save')}
                className={styles.save}
                onClick={() => onSaveClick(noteEdit.current.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {dialog && (
        <Modal>
          <DeleteWishlistModal
            title={t('deleteItem')}
            message={`${t('shureDeleteItem')} ${t('actionCantBeUndone')}`}
            onCancelClick={() => showDialog(false)}
            onDeleteClick={onItemDelete}
          />
        </Modal>
      )}
      {shareModal && (
        <Modal>
          <ShareModal
            title={t('Share')}
            onCancelClick={() => showModal(false)}
          />
        </Modal>
      )}
    </Layout>
  );
}

WishlistItemView.propTypes = {
  productPage: PropTypes.string.isRequired,
  productLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  wishlists: PropTypes.arrayOf(PropTypes.string).isRequired,
  wishlistIndex: PropTypes.number.isRequired,
  dialog: PropTypes.bool,
  showDialog: PropTypes.func,
  shareModal: PropTypes.bool,
  showModal: PropTypes.func,
  onSaveClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  itemImage: PropTypes.string,
  note: PropTypes.string,
  onItemSelect: PropTypes.func,
  onItemDelete: PropTypes.func,
};

WishlistItemView.defaultProps = {
  dialog: false,
  description: '',
  note: '',
  showDialog: () => {},
  shareModal: false,
  showModal: () => {},
  onSaveClick: () => {},
  onCloseClick: () => {},
  onItemSelect: () => {},
  onItemDelete: () => {},
  itemImage: '',
};

export default WishlistItemView;
