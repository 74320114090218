import { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordView from './PasswordView';
import formSchema from './schema';
import { updateRequestAsync, onLoad } from '../../store/updatePassword/slice';
import { setUserCheckPassword } from '../../store/auth/slice';
import { getUserSettingsAsync } from '../../store/user/slice';

function Password() {
  const { isLoading, isLoaded } = useSelector((state) => state.updatePassword, shallowEqual);
  const userData = useSelector((storeState) => storeState.auth);

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  };
  const {
    register, handleSubmit, formState: { errors }, getValues, setValue
  } = useForm(formOptions);

  const reset = () => {
    setValue('password', '');
    setValue('newPassword', '');
    setValue('confirmPassword', '');
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoading && isLoaded !== null) {
      dispatch(onLoad());
      reset();
    }
  }, [isLoading, isLoaded]);
  useEffect(() => {
    dispatch(getUserSettingsAsync({ dispatch })).then((res) => {
      dispatch(setUserCheckPassword({ email: res.payload.email }));
    });
  }, []);
  const isInvalid = () => {
    return (
      Object.keys(errors).length > 0
      || !getValues('password')
      || !getValues('newPassword')
      || getValues('password') === getValues('newPassword')
      || getValues('newPassword') !== getValues('confirmPassword')
    );
  };
  const onUpdatePassword = () => {
    const pass = getValues('password');
    const newPass = getValues('newPassword');
    const confirmPass = getValues('confirmPassword');
    dispatch(updateRequestAsync({ pass, newPass, confirmPass }));
  };

  return (
    <PasswordView
      disabled={isLoading || isInvalid()}
      isLoading={isLoading}
      reset={reset}
      register={register}
      onSubmit={handleSubmit(onUpdatePassword)}
      errors={errors}
      ownPassword={userData.ownPassword}
    />
  );
}

export default Password;
