import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  password: Yup
    .string()
    .required('passwordRequired')
    .max(32, 'passwordToLong')
    .matches(/^(?=.*[A-Z])/, 'uppercaseCharacter')
    .matches(/^(?=.*[!@#%&])/, 'specialCharacter'),

  newPassword: Yup
    .string()
    .required('passwordRequired')
    .max(32, 'passwordToLong')
    .matches(/^(?=.*[A-Z])/, 'uppercaseCharacter')
    .matches(/^(?=.*[!@#%&])/, 'specialCharacter'),

  confirmPassword: Yup.string()
    .required('confirmPasswordRequired')
    .oneOf([Yup.ref('newPassword')], 'passwordDontMatch'),
});
export default formSchema;
