import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import SettingsMyDetailsView from './SettingsMyDetailsView';
import { setUserSettingsNewAsync, updateUserSettingsAsync, } from '../../store/user/slice';
import { setUserAge, setUserCountry } from '../../store/user/action';
import formSchema from './schema';
import avatar from '../../assets/images/defaultAvatar.svg';

function SettingsMyDetails() {
  const dispatch = useDispatch();
  const userData = useSelector((storeState) => storeState.user);

  const formOptions = { resolver: yupResolver(formSchema), mode: 'onChange', };
  const {
    register, handleSubmit, formState,
    getValues, setValue
  } = useForm(formOptions);
  const { errors } = formState;

  const [newAvatarFile, setNewAvatarFile] = useState(null);

  const onSelectFile = useCallback((files) => {
    setNewAvatarFile(files[0]);
  }, []);

  const onSave = () => {
    if (userData.userSettings?.first_name !== getValues('firstName')
        || userData.userSettings?.last_name !== getValues('lastName')
        || userData.userSettings?.username !== getValues('username')
        || userData.userSettings?.email !== getValues('email')
        || userData.userSettings?.country !== (userData.country && userData.countries.data
          .filter((item) => item.code === userData.country)[0].id)
        || userData.userSettings.gender !== userData.gender
        || userData.userSettings?.age !== userData.age
        || newAvatarFile
    ) {
      dispatch(updateUserSettingsAsync({
        avatar: newAvatarFile,
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        username: getValues('username'),
        email: getValues('email'),
        country: userData.country && userData.countries.data.filter((item) => item.code === userData.country)[0].id,
        gender: t(userData.gender),
        age: userData.age,
      }));
    }
  };

  const newAvatar = useMemo(() => (
    newAvatarFile ? URL.createObjectURL(newAvatarFile) : (userData.newAvatarValue || avatar)
  ), [newAvatarFile, avatar, userData.newAvatarValue]);

  useEffect(() => {
    dispatch(setUserSettingsNewAsync({
      dispatch, setValue, globalT: t
    }));
  }, []);

  return (
    (
      <SettingsMyDetailsView
        getValues={getValues}
        defaultValues={userData.data}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        country={userData.country}
        setCountry={setUserCountry}
        gender={userData.gender}
        age={userData.age}
        setAge={setUserAge}
        onSelectFile={onSelectFile}
        newAvatar={newAvatar}
        avatar={userData.userSettings?.avatar}
        onSave={onSave}
      />
    )
  );
}

export default SettingsMyDetails;
