import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import HomePage from './pages/Home/Home';
import Login from './pages/LogIn/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import SetNewPassword from './pages/SetNewPassword/SetNewPassword';
import CheckEmail from './pages/CheckEmail/CheckEmail';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import NameRegistration from './pages/NameRegistration/NameRegistration';
import GenderRegistration from './pages/GenderRegistration/GenderRegistration';
import AgeRegistration from './pages/AgeRegistration/AgeRegistration';
import WearRegistration from './pages/WearRegistration/WearRegistration';
import SettingsMyDetails from './pages/SettingsMyDetails/SettingsMyDetails';
import Password from './pages/SettingsPassword/Password';
import Style from './pages/SettingsStyle/Style';
import Wear from './pages/SettingsWear/Wear';
import StyleRegistration from './pages/StyleRegistration/StyleRegistration';
import Wishlist from './pages/Wishlist/Wishlist';
import WishlistItem from './pages/WishlistItem/WishlistItem';
import './styles/globals.scss';
import AnalyticsRoute from './components/AnalyticsRoute/AnalyticsRoute';
import { getUserSettingsAsync } from './store/user/slice';
import useChromeExtension from './hooks/useChromeExtension';
import Activate from './pages/Activate/Activate';

function App() {
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSettingsAsync({ dispatch }));
  }, [accessToken]);

  useChromeExtension();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AnalyticsRoute />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/password/reset/confirm/:resetUID/:resetToken" element={<SetNewPassword />} />
          <Route path="/check-email" element={<CheckEmail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<CreateAccount />} />
          <Route path="/sign-up-name" element={<NameRegistration />} />
          <Route path="/sign-up-gender" element={<GenderRegistration />} />
          <Route path="/sign-up-age" element={<AgeRegistration />} />
          <Route path="/sign-up-wear" element={<WearRegistration />} />
          <Route path="/sign-up-style" element={<StyleRegistration />} />
          <Route path="/activate/:uid/:token" element={<Activate />} />
          <Route element={<PrivateRoute isActive={!!accessToken} />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/settings/password" element={<Password />} />
            <Route path="/settings/my-style/wear" element={<Wear />} />
            <Route path="/settings/my-style/style" element={<Style />} />
            <Route path="/settings/my-details" element={<SettingsMyDetails />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/wishlist/:wishlistId" element={<Wishlist />} />
            <Route path="/wishlist/:wishlistId/:itemId" element={<WishlistItem />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
