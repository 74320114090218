import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { t } from 'i18next';
import Layout from '../../layout/Layout/Layout';
import styles from './sass/Activate.module.scss';
import { ReactComponent as AlertIcon } from '../../assets/images/alert.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/checkGreen.svg';

function ActivateView({ isActivated }) {
  return (
    <Layout>
      <div className={styles.containerHeader}>
        {isActivated ? (
          <div className={cn(styles.block, styles.verified)}>
            <CheckIcon />
            <div className={styles.subBlockUnverified}>
              <p className={cn(styles.title, styles.text)}>{t('success')}</p>
              <p className={cn(styles.description, styles.text)}>
                {t('nowYouVerified')}
              </p>
            </div>
          </div>
        )
          : (
            <div className={cn(styles.block, styles.unverified)}>
              <AlertIcon />
              <div className={styles.subBlockUnverified}>
                <p className={cn(styles.title, styles.text)}>{t('failed')}</p>
                <p className={cn(styles.description, styles.text)}>
                  {t('Something went wrong')}
                </p>
              </div>
            </div>
          )}
      </div>
    </Layout>
  );
}
ActivateView.propTypes = {
  isActivated: PropTypes.bool.isRequired,
};
export default ActivateView;
