import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  shallowEqual,
  useDispatch,
  useSelector
} from 'react-redux';

import Layout from '../../../../layout/LayoutAuth/LayoutAuth';
import Button from '../../../../components/Button/Button';
import GoogleButton from '../../../../components/GoogleButton/GoogleButton';
import Loader from '../../../../components/Loader/Loader';
import InputWithIconAndLabels from '../../../../components/InputWithIconAndLabels/InputWithIconAndLabels';
import formSchema from './schema';
import { checkAsync } from '../../../../store/auth/slice';
import { setUserData } from '../../../../store/auth/actions';
import { getUserSettingsAsync } from '../../../../store/user/slice';
import styles from './scss/SignUpForm.module.scss';

function SignUpForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((storeState) => storeState.auth);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);

  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);

  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePasswordConfirm = () => {
    setPasswordConfirmShown(!passwordConfirmShown);
  };
  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  };
  const {
    register, handleSubmit, watch, formState: { errors }, getValues, setError
  } = useForm(formOptions);

  const onSubmit = () => {
    dispatch(checkAsync({ item: 'email', value: getValues('email') })).then((res) => {
      if (res.payload.exists) {
        setError(res.payload.item, {
          type: 'server',
          message: `${t(res.payload.item)} ${t('shouldBeUnique')}`,
        });
      } else {
        dispatch(setUserData({
          ...userData.dataUser,
          email: getValues('email'),
          password: getValues('password'),
          confirmPassword: getValues('confirmPassword')
        }));
        navigate('/sign-up-name');
      }
    });
  };

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserSettingsAsync({ dispatch })).then((data) => {
        if (data.payload.email
            && data.payload.first_name
            && data.payload.last_name
            && data.payload.username
            && data.payload.gender
            && data.payload.age
        ) {
          setError('email', {
            type: 'server',
            message: t('userExists'),
          });
          setError('password', {
            type: 'server',
            message: t('userExists'),
          });
          setError('confirmPassword', {
            type: 'server',
            message: t('userExists'),
          });
        } else {
          navigate('/sign-up-name');
        }
      });
    }
  }, [accessToken]);
  return (
    userData.isLoading ? (<Loader />) : (
      <Layout
        withSidebar
      >
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <InputWithIconAndLabels
            title={t('email')}
            type="text"
            placeholder={t('enterEmail')}
            onClickPassword={false}
            register={register}
            name="email"
            errors={errors.email}
          />
          <InputWithIconAndLabels
            title={t('password')}
            type={passwordShown ? 'text' : 'password'}
            placeholder={t('enterPassword')}
            onClickPassword={togglePassword}
            passwordShown={passwordShown}
            register={register}
            name="password"
            errors={errors.password}
          />
          <InputWithIconAndLabels
            title={t('confirmPassword')}
            type={passwordConfirmShown ? 'text' : 'password'}
            placeholder={t('enterPassword')}
            onClickPassword={togglePasswordConfirm}
            passwordShown={passwordConfirmShown}
            register={register}
            name="confirmPassword"
            errors={errors.confirmPassword}
          />
          <Button
            onClick={() => {}}
            isSubmit
            disabled={Object.keys(errors).length > 0
                || (watch().email === ''
                    || watch().password === ''
                    || watch().confirmPassword === ''
                )}
            title={t('continueWithEmail')}
            className={styles.pinkButtonStyle}
          />
        </form>
        <div>
          <span className={styles.orText}>{t('or')}</span>
        </div>
        <GoogleButton handleClick={() => {}} />
      </Layout>
    )
  );
}

export default SignUpForm;
