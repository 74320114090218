import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('emailRequired')
    .email(),
  password: Yup
    .string()
    .required('passwordRequired')
    .min(8, 'passwordToShort')
    .max(32, 'passwordToLong')
    .matches(/^(?=.*[A-Z])/, 'uppercaseCharacter')
    .matches(/^(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])/, 'specialCharacter'),

  confirmPassword: Yup.string()
    .required('confirmPasswordRequired')
    .oneOf([Yup.ref('password')], 'passwordDontMatch'),
});
export default formSchema;
