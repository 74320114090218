import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SetNewPasswordView from './SetNewPasswordView';
import formSchema from './schema';
import { setNewAsync, onLoad } from '../../store/setNewPassword/slice';

function SetNewPassword() {
  const navigate = useNavigate();
  const { isLoading, isLoaded } = useSelector((state) => state.setNewPassword, shallowEqual);

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  };
  const {
    register, handleSubmit, formState: { errors }, watch, getValues
  } = useForm(formOptions);

  const { resetUID, resetToken } = useParams();
  const dispatch = useDispatch();
  const sendPassword = useCallback(() => {
    localStorage.setItem('userPassword', getValues('password'));
    dispatch(setNewAsync({
      resetUID, resetToken, password: getValues('password'), confirm: getValues('confirmPassword')
    }));
  }, [getValues('password'), getValues('confirmPassword')]);

  useEffect(() => {
    if (!isLoading && isLoaded !== null) {
      dispatch(onLoad());
      navigate('/password-reset');
    }
  }, [isLoading, isLoaded]);

  return (
    <SetNewPasswordView
      isLoading={isLoading}
      disableButton={isLoading
          || Object.keys(errors).length > 0
          || watch().password === ''
          || watch().confirmPassword === ''}
      register={register}
      onFormSubmit={handleSubmit(sendPassword)}
      errors={errors}
    />
  );
}

export default SetNewPassword;
