import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import BackButton from '../../components/BackButton/BackButton';
import FormHeader from '../../components/FormHeader/FormHeader';
import Loader from '../../components/Loader/Loader';
import logo from '../../assets/images/envelope.svg';
import styles from './sass/CheckEmail.module.scss';

function CheckEmailView({
  userEmail,
  resend,
  onEmailClick,
  isLoading
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <FormHeader logo={logo} header={t('emailCheck')} subHeader={`${t('sentReset')} ${userEmail}`} />
          <Button
            onClick={onEmailClick}
            title={t('emailApp')}
            className={styles.button}
          />
          <div className={styles.textMessage}>
            <span className={styles.string}>{t('didntReceive')}</span>
            <button type="button" onClick={resend} className={styles.link}>{t('clickToResend')}</button>
          </div>
          <BackButton label={t('backToLogin')} path="/login" />
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

CheckEmailView.propTypes = {
  userEmail: PropTypes.string.isRequired,
  resend: PropTypes.func.isRequired,
  onEmailClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default CheckEmailView;
