import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('firstNameRequired'),
  lastName: Yup.string()
    .required('lastNameRequired'),
  username: Yup.string()
    .required('userNameRequired'),
  email: Yup.string()
    .email()
    .required('emailRequired'),

});
export default formSchema;
