import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import api from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  isLoaded: null
};

export const setNewAsync = createAsyncThunk(
  'setNewPassword/setNewAsync',
  async ({
    resetUID, resetToken, password, confirm
  }, { rejectWithValue }) => {
    const { data } = await instance.post(api.setNewPassword, {
      uid: resetUID,
      token: resetToken,
      new_password: password,
      re_new_password: confirm
    });
    return data !== undefined ? data : rejectWithValue('There was an error!');
  }
);

export const onLoad = createAction('setNewPassword/onLoad');

export const setNewPasswordSlice = createSlice({
  name: 'setNewPassword',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(onLoad, (state) => {
        state.isLoaded = null;
      })
      .addCase(setNewAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setNewAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = action.payload;
      })
      .addCase(setNewAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default setNewPasswordSlice.reducer;
