import PropTypes from 'prop-types';
import styles from './sass/FormHeader.module.scss';

function FormHeader({ logo, header, subHeader }) {
  return (
    <>
      <img src={logo} alt="sustie logo" />
      <h1 className={styles.header}>{header}</h1>
      <p className={styles.subHeader}>{subHeader}</p>
    </>
  );
}

FormHeader.propTypes = {
  logo: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired
};

export default FormHeader;
