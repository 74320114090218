import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import api from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  isLoaded: null
};

export const updateRequestAsync = createAsyncThunk(
  'updatePassword/updateRequestAsync',
  async ({ newPass, confirmPass, pass }, { rejectWithValue }) => {
    const o = {
      new_password: newPass,
      re_new_password: confirmPass,
      current_password: pass
    };
    const { data } = await instance.post(api.updatePassword, o);
    return data !== undefined ? data : rejectWithValue('There was an error!');
  }
);

export const onLoad = createAction('updatePassword/onLoad');

export const updatePasswordSlice = createSlice({
  name: 'updatePassword',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(onLoad, (state) => {
        state.isLoaded = null;
      })
      .addCase(updateRequestAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRequestAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = action.payload;
      })
      .addCase(updateRequestAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updatePasswordSlice.reducer;
