import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputWithIconAndLabels from '../../components/InputWithIconAndLabels/InputWithIconAndLabels';
import BackButton from '../../components/BackButton/BackButton';
import FormHeader from '../../components/FormHeader/FormHeader';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import logo from '../../assets/images/keyIcon.svg';
import styles from './sass/NewPasswordForm.module.scss';

function SetNewPasswordView({
  isLoading,
  disableButton,
  register,
  onFormSubmit,
  errors
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <FormHeader logo={logo} header={t('setNewPassword')} subHeader={t('passwordRules')} />
          <form className={styles.form} onSubmit={onFormSubmit}>
            <div className={styles.inputField}>
              <InputWithIconAndLabels
                onClickPassword={false}
                title={t('password')}
                placeholder={t('enterPassword')}
                type="password"
                name="password"
                register={register}
                errors={errors.password}
              />
            </div>
            <div className={styles.inputField}>
              <InputWithIconAndLabels
                onClickPassword={false}
                title={t('confirmPassword')}
                placeholder={t('enterPassword')}
                type="password"
                name="confirmPassword"
                register={register}
                errors={errors.confirmPassword}
              />
            </div>
            <Button
              isSubmit
              title={t('resetPassword')}
              className={styles.button}
              disabled={disableButton}
            />
          </form>
          <BackButton label={t('backToLogin')} path="/login" />
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

SetNewPasswordView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool.isRequired,
  register: PropTypes.func,
  onFormSubmit: PropTypes.func,
  errors: PropTypes.shape({
    password: PropTypes.shape({ message: PropTypes.string }),
    confirmPassword: PropTypes.shape({ message: PropTypes.string })
  })
};

SetNewPasswordView.defaultProps = {
  register: () => {},
  onFormSubmit: () => {},
  errors: {}
};

export default SetNewPasswordView;
