import PropTypes from 'prop-types';
import icon from '../../../../assets/images/gearIcon2.svg';
import styles from './sass/SmallGallery.module.scss';

function SmallGallery({
  title, items, onClickHandler, onOptionsClick
}) {
  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <p>{title}</p>
        <button type="button" onClick={onOptionsClick} className={styles.optionsButton}>
          <img src={icon} alt="options" className={styles.icon} />
        </button>
      </div>
      <button type="button" className={styles.buttonInvisible} onClick={onClickHandler}>
        <div className={styles.gallery}>
          {items.map((item) => <img className={styles.item} src={item.image_url} alt="item" key={item.id} />)}
        </div>
      </button>
    </div>
  );
}

SmallGallery.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    photo: PropTypes.string,
  })),
  onOptionsClick: PropTypes.func,
  onClickHandler: PropTypes.func.isRequired
};

SmallGallery.defaultProps = {
  items: [],
  onOptionsClick: () => {}
};

export default SmallGallery;
