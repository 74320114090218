import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/BackButton/BackButton';
import FormHeader from '../../components/FormHeader/FormHeader';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import logo from '../../assets/images/keyIcon.svg';
import styles from './sass/ForgotPasswordForm.module.scss';

function ForgotPasswordView({
  onFormSubmit,
  isLoading,
  errorMessage,
  register
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <FormHeader logo={logo} header={t('forgotQuestion')} subHeader={t('noWorries')} />
        <form className={styles.form} onSubmit={onFormSubmit}>
          <Input
            name="email"
            className={styles.input}
            label={t('emailLabel')}
            placeholder={t('enterEmail')}
            isError={!!errorMessage}
            register={register}
          />
          {errorMessage && <span className={styles.error}>{t(errorMessage)}</span>}
          <Button
            onClick={() => {}}
            isSubmit
            title={t('resetPassword')}
            disabled={isLoading}
          />
        </form>
        <BackButton label={t('backToLogin')} path="/login" />
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

ForgotPasswordView.propTypes = {
  onFormSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
  register: PropTypes.func
};

ForgotPasswordView.defaultProps = {
  onFormSubmit: () => {},
  isLoading: false,
  errorMessage: '',
  register: () => {}
};

export default ForgotPasswordView;
