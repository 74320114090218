import { components } from 'react-select';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Select.module.scss';

function Control({
  children,
  getValue,
  ...props
}) {
  const value = getValue();
  return (
    <components.Control {...props} getValue={getValue}>
      {value?.[0]?.icon && <span className={styles.icon}>{value?.[0]?.icon}</span>}
      {' '}
      {children}
    </components.Control>
  );
}

Control.propTypes = {
  children: PropTypes.node.isRequired,
  getValue: PropTypes.func.isRequired,
};

export default Control;
