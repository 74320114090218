import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyleView from './StyleView';
import { getStyles, getUserStyles, setStyles } from '../../store/clothing/slice';
import { onLoad, setCheckedStyles } from '../../store/clothing/actions';

function Style() {
  const {
    checkedStyles, styles, isLoading, isSet
  } = useSelector((state) => state.clothing);

  const dispatch = useDispatch();

  const update = useCallback(() => {
    dispatch(setStyles(checkedStyles));
  }, [checkedStyles]);

  const check = (event) => {
    let updatedList = [...checkedStyles];
    if (event.target.checked) {
      updatedList = [...checkedStyles, Number(event.target.value)];
    } else {
      updatedList.splice(checkedStyles.indexOf(Number(event.target.value)), 1);
    }
    dispatch(setCheckedStyles(updatedList));
  };

  useEffect(() => {
    if (!isLoading && isSet) {
      dispatch(onLoad);
    }
  }, [isLoading, isSet]);
  useEffect(() => {
    if (!styles) dispatch(getStyles());
    if (checkedStyles.length === 0) dispatch(getUserStyles());
  }, []);
  return (
    <StyleView
      checked={checkedStyles}
      checkHandler={check}
      updateHandler={update}
      isLoading={isLoading}
      styles={styles}
    />
  );
}

export default Style;
