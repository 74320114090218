import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import instance from '../../services/axios';
import api from '../../constants/api';
import {
  setCountries,
  setUserSettings,
  setUserSettingsAvatar,
  setUserCountry,
  setUserAge,
  setUserGender,
  setNewAvatarValue
} from './action';
import { Ages } from '../../constants/general';
import { ReactComponent as AgeIcon } from '../../assets/images/age.svg';

export const initialState = {
  data: null,
  error: null,
  isLoading: false,
  userSettings: {},
  userImage: null,
  countries: [],
  country: null,
  gender: '',
  age: '',
  newAvatarValue: '',
};

export const getUserAsync = createAsyncThunk(
  'user/getUser',
  async () => {
    const { data } = await instance.get(api.me);
    return data;
  }
);
export const getUserSettingsAsync = createAsyncThunk(
  'user/getUserSettings',
  async ({ dispatch }) => {
    const { data } = await instance.get(api.usersMe);
    dispatch(setUserSettings(data));
    return data;
  }
);
export const getCountriesAsync = createAsyncThunk(
  'user/getCountriesAsync',
  async ({ dispatch }) => {
    const { data } = await instance.get(api.countries);
    dispatch(setCountries({ data }));
    return data;
  }
);

export const setUserSettingsNewAsync = createAsyncThunk(
  'user/setUserSettingsNewAsync',
  ({
    dispatch, setValue, globalT
  }) => {
    const ageOptions = Ages.map((
      { title, age: ageLabel }
    ) => ({ label: `${globalT(title)} ${ageLabel}`, value: ageLabel, icon: <AgeIcon /> }));
    dispatch(getCountriesAsync({ dispatch })).then((value) => {
      instance.get(api.usersMe)
        .then((res) => {
          const newData = res.data;
          setValue('firstName', newData.first_name);
          setValue('lastName', newData.last_name);
          setValue('username', newData.username);
          setValue('email', newData.email);
          batch(() => {
            dispatch(setNewAvatarValue(newData.avatar));
            dispatch(setUserCountry(
              value.payload?.filter((item) => item?.id === newData.country)[0]?.code
            ));
            dispatch(setUserAge(ageOptions.filter((item) => item?.label === newData.age)[0].label));
            dispatch(setUserGender(newData.gender));
            dispatch(setUserSettings(newData));
          });
        });
    });
  }
);

export const updateUserSettingsAsync = createAsyncThunk(
  'user/updateUserSettings',
  async ({
    firstName, lastName, username, email, country, gender, age, avatar
  }, { dispatch }) => {
    const formData = new FormData();
    if (avatar) {
      formData.append('avatar', avatar);
    }
    if (country) {
      formData.append('country', country);
    }
    if (firstName) {
      formData.append('first_name', firstName);
    }
    if (lastName) {
      formData.append('last_name', lastName);
    }
    if (username) {
      formData.append('username', username);
    }
    if (email) {
      formData.append('email', email);
    }
    if (gender) {
      formData.append('gender', gender);
    }
    if (age) {
      formData.append('age', age);
    }

    const { data } = await instance.patch(api.usersDetails, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(setUserSettings(data));
    return data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(setUserCountry, (state, action) => {
        state.country = action.payload;
      })
      .addCase(setUserGender, (state, action) => {
        state.gender = action.payload;
      })
      .addCase(setUserAge, (state, action) => {
        state.age = action.payload;
      })
      .addCase(setNewAvatarValue, (state, action) => {
        state.newAvatarValue = action.payload;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setUserSettings, (state, action) => {
        state.userSettings = action.payload;
      })
      .addCase(setCountries, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(setUserSettingsAvatar, (state, action) => {
        state.userImage = action.payload;
      })
      .addCase(getUserSettingsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserSettingsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getUserSettingsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setUserSettingsNewAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setUserSettingsNewAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(setUserSettingsNewAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUserSettingsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserSettingsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateUserSettingsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
