import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setUserData } from '../../store/auth/actions';
import AgeRegistrationView from './AgeRegistrationView';
import { Ages } from '../../constants/general';
import { getUserSettingsAsync, updateUserSettingsAsync } from '../../store/user/slice';
import { ReactComponent as AgeIcon } from '../../assets/images/age.svg';

function AgeRegistration() {
  const navigate = useNavigate();
  const [age, setAge] = useState('');

  const dispatch = useDispatch();
  const userData = useSelector((storeState) => storeState.auth);

  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);
  const { t: globalT } = useTranslation();

  const ageOptions = Ages.map((
    { title, age: ageLabel }
  ) => ({ label: `${globalT(title)} ${ageLabel}`, value: ageLabel, icon: <AgeIcon /> }));

  const onSubmit = () => {
    if (accessToken) {
      dispatch(updateUserSettingsAsync({
        age: ageOptions.filter((data) => data.value === age)[0].label
      }));
      navigate('/sign-up-wear');
    } else {
      dispatch(setUserData({
        ...userData.dataUser,
        age: `${t(Ages.filter((data) => data.age === age)[0].title)} ${Ages.filter((data) => data.age === age)[0].age}`
      }));
      navigate('/sign-up-wear');
    }
  };

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserSettingsAsync({ dispatch })).then((data) => {
        setAge(data.payload.age.split(' ').slice(1).join(' '));
      });
    }
  }, []);

  return (
    <AgeRegistrationView onSubmit={onSubmit} age={age} setAge={setAge} />
  );
}
export default AgeRegistration;
