import { useNavigate } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import StyleRegistrationView from './StyleRegistrationView';
import { getUserClosingStyleAsync, signUpAsync } from '../../store/auth/slice';
import { setUserData } from '../../store/auth/actions';
import { getUserSettingsAsync } from '../../store/user/slice';
import { setStyles } from '../../store/clothing/slice';

function StyleRegistration() {
  const [checked, setChecked] = useState([]);

  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);
  const dispatch = useDispatch();
  const userData = useSelector((storeState) => storeState.auth);

  const handleCheck = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  useEffect(() => {
    dispatch(getUserClosingStyleAsync({ dispatch }));
    if (accessToken) {
      dispatch(getUserSettingsAsync({ dispatch })).then((data) => {
        setChecked(data.payload.type_of_style);
      });
    }
  }, [accessToken]);

  const onSubmit = useCallback(() => {
    if (accessToken) {
      dispatch(setStyles(checked)).then(() => { navigate('/'); });
    } else {
      batch(() => {
        dispatch(setUserData({
          ...userData.dataUser,
          styleOfClothing: checked.map((toNumber) => Number(toNumber))
        }));
        dispatch(signUpAsync({
          email: userData.dataUser.email,
          password: userData.dataUser.password,
          confirmPassword: userData.dataUser.confirmPassword,
          firstName: userData.dataUser.firstName,
          lastName: userData.dataUser.lastName,
          username: userData.dataUser.userName,
          gender: userData.dataUser.gender,
          age: userData.dataUser.age,
          typeOfClothing: userData.dataUser.typeOfClothing,
          styleOfClothing: checked.map((toNumber) => Number(toNumber))
        })).then(() => { navigate('/'); });
      });
    }
  }, [checked, userData.dataUser]);

  return (
    <StyleRegistrationView
      userData={userData}
      onSubmit={onSubmit}
      handleCheck={handleCheck}
      checked={checked}
    />
  );
}

export default StyleRegistration;
