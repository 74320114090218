import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import api from '../../constants/api';
import {
  login, logout, setOwnPassword, setUserClosingStyle, setUserClosingType, setUserData, signUp
} from './actions';

export const initialState = () => ({
  tokens: {
    accessToken: localStorage.getItem('accessToken') || undefined,
    refreshToken: localStorage.getItem('refreshToken') || undefined,
  },
  dataUser: {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    userName: '',
    gender: '',
    age: '',
    typeOfClothing: [],
    styleOfClothing: [],
  },
  ownPassword: true,
  closingType: [],
  closingStyle: [],
  error: null,
  isLoading: false,
});

export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async ({ email, password }, { dispatch }) => {
    const { data } = await instance.post(api.login, {
      grant_type: 'password',
      username: email,
      password,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    });
    const { access_token: accessToken, refresh_token: refreshToken } = data;
    dispatch(login({ accessToken, refreshToken }));
    return { accessToken, refreshToken };
  }
);

export const loginWithGoogleAsync = createAsyncThunk('auth/loginWithGoogle', async (token, { dispatch }) => {
  const data = {
    token,
    backend: 'google-oauth2',
    grant_type: 'convert_token',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  };

  const {
    data:
    { access_token: accessToken, refresh_token: refreshToken }
  } = await instance.post(api.convertToken, data);

  dispatch(login({ accessToken, refreshToken }));
  return { accessToken, refreshToken };
});

export const checkAsync = createAsyncThunk(
  'auth/checkAsync',
  async ({ item, value }) => {
    const { data } = await instance.post(api.check, {
      item, value
    });
    return data;
  }
);

export const signUpAsync = createAsyncThunk(
  'auth/SignUpAsync',
  async ({
    email, password, confirmPassword, firstName, lastName, username, gender, age, typeOfClothing, styleOfClothing
  }, { dispatch }) => {
    const { data } = await instance.post(api.signUp, {
      email,
      password,
      password_re: confirmPassword,
      first_name: firstName,
      last_name: lastName,
      username,
      gender,
      age,
      type_of_clothing: typeOfClothing,
      type_of_style: styleOfClothing
    });
    const { access_token: accessToken, refresh_token: refreshToken } = data;
    dispatch(signUp({ accessToken, refreshToken }));
    return data;
  }
);
export const getUserClosingTypeAsync = createAsyncThunk(
  'auth/getUserClosingTypeAsync',
  async ({ dispatch }) => {
    const { data } = await instance.get(api.closingType);

    dispatch(setUserClosingType({ data }));
    return data;
  }
);
export const getUserClosingStyleAsync = createAsyncThunk(
  'auth/getUserClosingStyleAsync',
  async ({ dispatch }) => {
    const { data } = await instance.get(api.closingStyle);

    dispatch(setUserClosingStyle({ data }));
    return data;
  }
);

export const setUserCheckPassword = createAsyncThunk(
  'auth/getUserCheckPasswordAsync',
  async ({ email }, { dispatch }) => {
    const { data } = await instance.post(api.checkPassword, { email });
    dispatch(setOwnPassword({ data }));
    return data;
  }
);
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState(),
  extraReducers: (builder) => {
    builder
      .addCase(login, (state, action) => {
        const { accessToken, refreshToken } = action.payload;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        state.tokens = { accessToken, refreshToken };
      })
      .addCase(logout, () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        return initialState();
      })
      .addCase(setOwnPassword, (state, action) => {
        state.ownPassword = action.payload.data.own_password;
      })
      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(signUp, (state, action) => {
        const { accessToken, refreshToken } = action.payload;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        state.tokens = { accessToken, refreshToken };
      })
      .addCase(setUserData, (state, action) => {
        state.dataUser = action.payload;
      })
      .addCase(setUserClosingType, (state, action) => {
        state.closingType = action.payload.data;
      })
      .addCase(getUserClosingTypeAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserClosingTypeAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserClosingTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setUserClosingStyle, (state, action) => {
        state.closingStyle = action.payload.data;
      })
      .addCase(getUserClosingStyleAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserClosingStyleAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserClosingStyleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(signUpAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(signUpAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(checkAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(checkAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
