import PropTypes from 'prop-types';
import { t } from 'i18next';
import styleIcon from '../../assets/images/styleIcon.svg';
import checkbox from '../../assets/images/checkPink.svg';
import check from '../../assets/images/check.svg';
import styles from './sass/StyleItem.module.scss';

function StyleItem({
  isChecked, handleClick, text, image, id
}) {
  return (
    <label htmlFor={text} className={styles.card}>
      <div className={`${styles.wearItemHeader} ${isChecked ? styles.active : styles.inactive}`}>
        <img src={styleIcon} className={styles.styleIcon} alt="" />
        <p className={styles.styleText}>{t(text)}</p>
        <div className={styles.checkboxContainer}>
          <input
            id={text}
            value={id}
            type="checkbox"
            checked={isChecked}
            onChange={handleClick}
            className={styles.checkboxButton}
          />
          <img src={isChecked ? check : checkbox} className={styles.iconButton} alt="" />
        </div>
      </div>
      <div className={`${styles.itemBody} ${isChecked ? styles.active : styles.inactive}`}>
        <img src={image} className={styles.img} alt="" />
      </div>
    </label>
  );
}

StyleItem.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default StyleItem;
