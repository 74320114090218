import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Button.module.scss';

export const BUTTONS_TYPES = {
  PRIMARY: 'primary',
  WHITE: 'white',
  GRAY: 'gray',
  RED: 'red',
};

export default function Button({
  title, className, onClick, typeButton, isSubmit, disabled
}) {
  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      className={cn(styles.button, styles[typeButton], className)}
      data-testid="button"
      disabled={disabled}
    >
      {title}
    </button>
  );
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  isSubmit: PropTypes.bool,
  typeButton: PropTypes.oneOf(BUTTONS_TYPES),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  className: '',
  typeButton: BUTTONS_TYPES.PRIMARY,
  disabled: false,
  isSubmit: false,
  onClick: () => {}
};
