import axios from 'axios';
import api from '../constants/api';
import { login, logout } from '../store/auth/actions';

let store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const injectStore = (_store) => {
  store = _store;
};

const getRefreshToken = () => {
  const { auth: { tokens: { refreshToken } } } = store.getState(false);
  return instance.post(api.refresh, {
    refresh: refreshToken,
  }, {
    withoutAuth: true
  });
};

instance.interceptors.request.use(
  (config) => {
    const newConfig = config;

    const { auth: { tokens: { accessToken } } } = store.getState();
    if (!config.withoutAuth && accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      try {
        const { data } = await getRefreshToken();
        const { access: accessToken, refresh: refreshToken } = data;
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };
        store.dispatch(login({ accessToken, refreshToken }));
      } catch (e) {
        store.dispatch(logout());
      }
      return instance(config);
    }
    throw error;
  }
);

export default instance;
