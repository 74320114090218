import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import InputWithIconAndLabels from '../../../../components/InputWithIconAndLabels/InputWithIconAndLabels';
import Loader from '../../../../components/Loader/Loader';
import Layout from '../../../../layout/LayoutAuth/LayoutAuth';
import formSchema from './schema';
import { setUserData } from '../../../../store/auth/actions';
import { checkAsync } from '../../../../store/auth/slice';
import { getUserSettingsAsync, updateUserSettingsAsync } from '../../../../store/user/slice';
import { ButtonSpace } from '../../../../constants/general';
import styles from './sass/NameSignUpForm.module.scss';

function NameSignUpForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((storeState) => storeState.auth);
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);
  const [dataUser, setDataUser] = useState(null);

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  };
  const {
    register, handleSubmit, watch, setError, setValue, formState: { errors }, getValues
  } = useForm(formOptions);

  const onSubmit = () => {
    if (accessToken) {
      dispatch(updateUserSettingsAsync({
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        username: getValues('userName'),
      }));
      navigate('/sign-up-gender');
    } else {
      dispatch(checkAsync({ item: 'username', value: getValues('userName') })).then((res) => {
        if (res.payload.exists) {
          setError('userName', {
            type: 'server',
            message: `${t('userName')} ${t('shouldBeUnique')}`,
          });
        } else {
          dispatch(setUserData({
            ...userData.dataUser,
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            userName: getValues('userName')
          }));
          navigate('/sign-up-gender');
        }
      });
    }
  };
  useEffect(() => {
    if (accessToken) {
      dispatch(getUserSettingsAsync({ dispatch })).then((data) => {
        setDataUser(data.payload);
        setValue('firstName', data.payload.first_name);
        setValue('lastName', data.payload.last_name);
        setValue('userName', data.payload.username);
      });
    }
  }, []);
  return (
    userData.isLoading ? (<Loader />) : (
      <Layout
        titleButtonNext={t('next')}
        titleButtonBack={t('back')}
        handleClick={handleSubmit(onSubmit)}
        withSidebar
        buttonsSpace={ButtonSpace['25']}
        isDisable={Object.keys(errors).length > 0
          || (watch().firstName === ''
          || watch().lastName === ''
          || watch().userName === ''
          )}
      >
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)} action="">
          <InputWithIconAndLabels
            title={t('firstName')}
            type="text"
            placeholder={t('enterFirstName')}
            onClickPassword={false}
            register={register}
            name="firstName"
            errors={errors.firstName}
            defaultValue={dataUser?.first_name}
          />
          <InputWithIconAndLabels
            title={t('lastName')}
            type="text"
            placeholder={t('enterLastName')}
            onClickPassword={false}
            register={register}
            name="lastName"
            errors={errors.lastName}
            defaultValue={dataUser?.last_name}
          />
          <InputWithIconAndLabels
            title={t('userName')}
            type="text"
            placeholder={t('enterUserName')}
            onClickPassword={false}
            register={register}
            name="userName"
            errors={errors.userName}
            defaultValue={dataUser?.username}
          />
          <input type="submit" className={styles.submit} />
        </form>
      </Layout>
    )
  );
}

export default NameSignUpForm;
