import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import googleIcon from '../../assets/images/googleIcon.svg';
import styles from './sass/GoogleButton.module.scss';
import { loginWithGoogleAsync } from '../../store/auth/slice';

function GoogleButton() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => dispatch(loginWithGoogleAsync(tokenResponse.access_token)),
  });
  return (
    <button type="button" className={styles.googleButton} onClick={() => login()}>
      <img src={googleIcon} alt="" className={styles.googleIcon} />
      <span className={styles.googleText}>{t('continueWithGoogle')}</span>
    </button>
  );
}

export default GoogleButton;
