import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function AnalyticsRoute() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location.pathname, location.search]);
  return <Outlet />;
}

export default AnalyticsRoute;
