import { useTranslation } from 'react-i18next';
import Layout from '../../layout/Layout/Layout';
import Collection from './components/Collection';
import comm from '../../assets/images/chat.svg';
import goal from '../../assets/images/spark.svg';
import styles from './sass/Home.module.scss';

export default function HomeView() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className={styles.topHalf}>
        <div className={styles.container}>
          <div className={styles.leftColumn}>
            <span className={styles.subHeading}>{t('launch')}</span>
            <span className={styles.heading}>{t('chromeExtension')}</span>
            <span className={styles.text}>{t('weMakeSustainable')}</span>
          </div>
          <div className={styles.rightColumn}>
            <Collection icon={comm} label={t('community')} text={t('weBroughtTogether')} />
            <Collection icon={goal} label={t('goal')} text={t('theGoalIs')} />
          </div>
        </div>
      </div>
      <div className={styles.overlay}>
        <iframe
          className={styles.video}
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/E0Rcgib-JOI"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="Embedded youtube"
        />
      </div>
    </Layout>
  );
}
