import ReactFlagsSelect from 'react-flags-select';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './sass/CountrySelect.module.scss';

function CountrySelect({
  onChange, value, className, label
}) {
  return (
    <div className={className}>
      {label && <span className={styles.label}>{label}</span>}
      <ReactFlagsSelect
        searchable
        selected={value}
        onSelect={onChange}
        selectButtonClassName={styles.selectButton}
      />
    </div>
  );
}

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
};

CountrySelect.defaultProps = {
  className: '',
  label: '',
  value: ''
};

export default CountrySelect;
