import PropTypes from 'prop-types';
import cn from 'classnames';
import checkbox from '../../../assets/images/checkbox.svg';
import check from '../../../assets/images/check.svg';
import styles from '../sass/GenderRegistration.module.scss';

export default function GenderItem({
  title, icon, isActive, onClick
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.genderItem, isActive ? styles.active : styles.inactive)}
    >
      <img src={icon} alt="" className={styles.icon} />
      <p>{title}</p>
      <div className={styles.iconButton}>
        <img src={isActive ? check : checkbox} alt="gender icon" />
      </div>
    </button>
  );
}

GenderItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
