import everydayWear from '../assets/images/everydayWear.svg';
import vintage from '../assets/images/vintage.svg';
import grunge from '../assets/images/grunge.svg';
import bohemian from '../assets/images/bohemian.svg';
import officeWear from '../assets/images/officeWear.svg';
import dateNight from '../assets/images/dateNight.svg';
import activeWear from '../assets/images/activeWear.svg';
import maximalist from '../assets/images/maximalist.svg';
import scandi from '../assets/images/scandi.svg';
import streetWear from '../assets/images/streetWear.svg';

import male from '../assets/images/male.svg';
import female from '../assets/images/female.svg';

export const ButtonTypes = { Pink: 'pinkButton', Back: 'backButton', Next: 'nextButton' };
export const ButtonSpace = {
  11: 'margin11', 13: 'margin13', 16: 'margin16', 25: 'margin25', 33: 'margin33'
};

export const HomeNavPages = [
  { id: 0, title: 'myDetails', routes: ['/settings/my-details'] },
  { id: 1, title: 'password', routes: ['/settings/password'] },
  { id: 2, title: 'myStyle', routes: ['/settings/my-style/wear', '/settings/my-style/style'] }
];

export const Ages = [
  { title: 'under', age: '18' },
  { title: 'between', age: '18-25' },
  { title: 'between', age: '26-35' },
  { title: 'between', age: '36-45' },
  { title: 'over', age: '46' }
];

export const Genders = [
  { title: 'female', icon: female },
  { title: 'male', icon: male }
];

export const Wear = [
  'bottoms',
  'outerwear',
  'allInOnes',
  'sleepwear',
  'workOut',
  'tops',
  'dresses',
  'footwear',
  'productSets',
  'underwear',
];

export const SideTabs = [
  { id: 0, title: 'wear', route: '/settings/my-style/wear' },
  { id: 1, title: 'style', route: '/settings/my-style/style' },
//  { id: 2, title: 'brands' },
//  { id: 3, title: 'answers' },
];

export const Styles = [
  { title: 'everyDayWear', image: everydayWear },
  { title: 'vintage', image: vintage },
  { title: 'grunge', image: grunge },
  { title: 'bohemian', image: bohemian },
  { title: 'officeWear', image: officeWear },
  { title: 'dateNight', image: dateNight },
  { title: 'activeWear', image: activeWear },
  { title: 'maximalist', image: maximalist },
  { title: 'scandi', image: scandi },
  { title: 'streetwear', image: streetWear },
];

export const sidebarItems = [
  { title: 'createAccount', subtitle: 'pleaseCreateAccount', step: 0 },
  { title: 'yourName', subtitle: 'whatIsYourName', step: 1 },
  { title: 'yourGender', subtitle: 'describeYourGenderStyle', step: 2 },
  { title: 'yourAge', subtitle: 'whatAgeGroup', step: 3 },
  { title: 'whatDoYouWear', subtitle: 'whatTypeOfClosing', step: 4 },
  { title: 'yourStyle', subtitle: 'describesYourStyle', step: 5 }
];

export const feedBackLink = 'https://sustie.io/feedback';
export const version = 'v.1.3';
