import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Layout from '../../layout/Layout/Layout';
import InputWithLabels from '../../components/InputWithLabels/InputWithLabels';
import Button, { BUTTONS_TYPES } from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import style from './sass/Password.module.scss';

function PasswordView({
  reset, disabled, isLoading,
  register, onSubmit, errors, ownPassword
}) {
  const { t } = useTranslation();

  return (
    <Layout homenav>
      <div className={style.container}>
        <form className={style.content} onSubmit={onSubmit}>
          <div className={style.divider} />
          <div className={style.header}>
            <h1>{t('password')}</h1>
            <p>{t('pleaseEnterCurrent')}</p>
          </div>
          <div className={style.divider} />
          <InputWithLabels
            label={t('currentPassword')}
            type="password"
            name="password"
            value=""
            placeholder=""
            register={register}
            errors={errors?.password}
            disabled={!ownPassword}
          />
          <div className={style.divider} />
          <InputWithLabels
            label={t('newPassword')}
            tip={t('newPasswordLength')}
            type="password"
            name="newPassword"
            value=""
            placeholder=""
            register={register}
            errors={errors?.newPassword}
            disabled={!ownPassword}
          />
          <div className={style.divider} />
          <InputWithLabels
            label={t('confirmNew')}
            type="password"
            name="confirmPassword"
            value=""
            placeholder=""
            register={register}
            errors={errors?.confirmPassword}
            disabled={!ownPassword}
          />
          <div className={style.divider} />
          <div className={style.footer}>
            <Button
              title={t('cancel')}
              onClick={reset}
              typeButton={BUTTONS_TYPES.GRAY}
            />
            <Button
              title={t('updatePassword')}
              onClick={() => {}}
              disabled={disabled}
              isSubmit
            />
          </div>
        </form>
      </div>
      {isLoading && <Loader />}
    </Layout>
  );
}

PasswordView.propTypes = {
  ownPassword: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  register: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({
    password: PropTypes.shape({}),
    newPassword: PropTypes.shape({}),
    confirmPassword: PropTypes.shape({}),
  })
};

PasswordView.defaultProps = {
  register: () => {},
  onSubmit: () => {},
  errors: null
};

export default PasswordView;
