import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Layout from '../../../../layout/LayoutAuth/LayoutAuth';
import Button, { BUTTONS_TYPES } from '../../../../components/Button/Button';
import GoogleButton from '../../../../components/GoogleButton/GoogleButton';
import InputWithIconAndLabels from '../../../../components/InputWithIconAndLabels/InputWithIconAndLabels';
import Loader from '../../../../components/Loader/Loader';
import { loginAsync } from '../../../../store/auth/slice';
import formSchema from './schema';
import logo from '../../../../assets/images/sustielogo.svg';
import styles from './sass/LoginForm.module.scss';

function LoginForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);
  const userData = useSelector((storeState) => storeState.auth);

  const formOptions = { resolver: yupResolver(formSchema), mode: 'onChange', };
  const {
    register, handleSubmit, formState, getValues
  } = useForm(formOptions);
  const { errors } = formState;

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const login = useCallback(() => {
    dispatch(loginAsync({ email: getValues('email'), password: getValues('password') }));
  }, [getValues('email'), getValues('password')]);

  useEffect(() => {
    if (accessToken) {
      navigate('/');
    }
  }, [accessToken]);

  return (
    userData.isLoading ? (<Loader />) : (
      <Layout>
        <img src={logo} alt="sustie logo" />
        <h1 className={styles.header}>{t('accountLogin')}</h1>
        <p className={styles.subHeader}>{t('welcomeBack')}</p>
        <form className={styles.form} onSubmit={handleSubmit(login)}>
          <InputWithIconAndLabels
            title={t('email')}
            type="text"
            placeholder={t('enterEmail')}
            onClickPassword={false}
            register={register}
            name="email"
            errors={errors.email}
          />
          <InputWithIconAndLabels
            title={t('password')}
            type={passwordShown ? 'text' : 'password'}
            placeholder={t('enterPassword')}
            onClickPassword={togglePassword}
            passwordShown={passwordShown}
            register={register}
            name="password"
            errors={errors.password}
          />
          <div className={styles.checkboxContainer}>
            <label htmlFor="checkbox" className={styles.checkbox}>
              <input type="checkbox" id="checkbox" name="checkbox" value="checkbox" />
              <span>{t('remember')}</span>
            </label>
            <Link className={styles.reactLink} to="/forgot-password"><p>{t('forgotPassword')}</p></Link>
          </div>
          <Button isSubmit onClick={() => {}} typeButton={BUTTONS_TYPES.PRIMARY} title={t('continueWithEmail')} />
        </form>
        <div>
          <span className={styles.orText}>{t('or')}</span>
        </div>
        <GoogleButton handleClick={() => {}} />
        <div className={styles.signUp}>
          <p className={styles.signUpText}>{t('dontHaveAccount')}</p>
          <Link to="/sign-up" className={styles.reactLink}>
            <p className={styles.signUpLink}>{t('signUp')}</p>
          </Link>
        </div>
      </Layout>
    )
  );
}

export default LoginForm;
