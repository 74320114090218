import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import api from '../../constants/api';

export const initialState = () => ({
  error: null,
  isLoading: false,
  isLoaded: null
});

export const onLoad = createAction('resetPassword/onLoad');

export const sendEmailAsync = createAsyncThunk(
  'resetPassword/sendEmailAsync',
  async ({ email }, { rejectWithValue }) => {
    const { data } = await instance.post(api.resetPassword, { email });
    return data !== undefined ? data : rejectWithValue('There was an error!');
  }
);

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: initialState(),
  extraReducers: (builder) => {
    builder
      .addCase(onLoad, initialState)
      .addCase(sendEmailAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendEmailAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = action.payload;
      })
      .addCase(sendEmailAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default resetPasswordSlice.reducer;
