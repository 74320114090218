import { configureStore } from '@reduxjs/toolkit';
import translates from './translates/slice';
import auth from './auth/slice';
import user from './user/slice';
import resetPassword from './resetPassword/slice';
import resendActivation from './resendActivation/slice';
import updatePassword from './updatePassword/slice';
import setNewPassword from './setNewPassword/slice';
import clothing from './clothing/slice';
import wishlist from './wishlist/slice';

const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      translates,
      auth,
      user,
      resetPassword,
      updatePassword,
      setNewPassword,
      wishlist,
      clothing,
      resendActivation
    },
    preloadedState
  });
};

export default setupStore;
