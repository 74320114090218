import PropTypes from 'prop-types';
import { t } from 'i18next';
import checked from '../../../assets/images/сheckboxChecked.svg';
import checkbox from '../../../assets/images/checkbox.svg';
import styles from '../sass/AgeRegistration.module.scss';

function AgeItem({
  isChecked, handleClick, text, numberRange
}) {
  return (
    <button
      type="button"
      onClick={handleClick}
      className={`${styles.ageItem} ${isChecked ? styles.active : styles.inactive}`}
    >
      <div className={styles.iconButton}>
        <img src={isChecked ? checked : checkbox} alt="" />
      </div>
      <p>{t(text)}</p>
      <span>{numberRange}</span>
    </button>
  );
}

AgeItem.propTypes = {
  text: PropTypes.string.isRequired,
  numberRange: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default AgeItem;
