import { t } from 'i18next';
import PropTypes from 'prop-types';
import StyleItem from '../../components/StyleItem/StyleItem';
import LayoutAuth from '../../layout/LayoutAuth/LayoutAuth';
import Loader from '../../components/Loader/Loader';
import { ButtonSpace } from '../../constants/general';
import styles from './sass/StyleRegistration.module.scss';

function StyleRegistrationView({
  userData, onSubmit, handleCheck, checked
}) {
  return (userData.isLoading ? (<Loader />) : (
    <LayoutAuth
      titleButtonNext={t('next')}
      titleButtonBack={t('back')}
      buttonsSpace={ButtonSpace['16']}
      handleClick={onSubmit}
      buttonCentered
      withSidebar
    >
      <p className={styles.text}>{t('chooseAsManyAsYouWant')}</p>
      <div className={styles.styleContainer}>
        {userData.closingStyle.map(({ id, name, image }) => (
          <StyleItem
            key={id}
            handleClick={handleCheck}
            text={name}
            image={image}
            isChecked={checked.map((toNumber) => Number(toNumber)).includes(id)}
            id={id}
          />
        ))}
      </div>
    </LayoutAuth>
  )
  );
}
StyleRegistrationView.propTypes = {
  userData: PropTypes.shape({
    isLoading: PropTypes.bool,
    closingStyle: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      image: PropTypes.string
    }))
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};
export default StyleRegistrationView;
