import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReactComponent as UploadIcon } from '../../assets/images/upload.svg';
import styles from './sass/DragAndDropFiles.module.scss';

function DragAndDropFiles({
  onDrop, accept, multiple, maxSize, className
}) {
  const { t } = useTranslation('', { keyPrefix: 'dragAndDropFiles' });

  return (
    <Dropzone onDrop={onDrop} maxSize={maxSize} multiple={multiple} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: cn(styles.dragAndDropFiles, className) })}>
          <UploadIcon className={styles.image} />
          <p className={styles.text}>
            <span className={styles.textActive}>{t('clickToUpload')}</span>
            {' '}
            <span>{t('orDragAndDrop')}</span>
          </p>
          <p className={styles.terms}>
            {t('terms')}
          </p>
          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  );
}

DragAndDropFiles.propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.shape({}),
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  className: PropTypes.string,
};

DragAndDropFiles.defaultProps = {
  onDrop: undefined,
  accept: { 'image/*': [] },
  multiple: false,
  maxSize: 25 * 1024 * 1024,
  className: '',
};

export default DragAndDropFiles;
