import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import tips from '../../assets/images/tips.svg';
import eye from '../../assets/images/eye.svg';
import eyeOff from '../../assets/images/eyeOff.svg';
import warning from '../../assets/images/warning.svg';
import styles from './sass/InputWithIconAndLabels.module.scss';

function InputWithIconAndLabels({
  title,
  placeholder,
  hintText,
  secondTitle,
  type,
  onClickPassword,
  passwordShown,
  errors,
  register,
  name,
  className,
  rightIcon,
  leftIcon,
  defaultValue,
  disabled
}) {
  const { t } = useTranslation();

  const isErrorExist = Object.keys(errors).length > 0;

  return (
    <div className={styles.inputContainer}>
      <Input
        className={className}
        rightIcon={rightIcon}
        leftIcon={leftIcon}
        defaultValue={defaultValue}
        label={title}
        placeholder={placeholder}
        type={type}
        register={register}
        name={name}
        isError={isErrorExist}
        disabled={disabled}
        iconShowPassword={onClickPassword && (
        <button type="button" className={styles.buttonInvisible} onClick={onClickPassword}>
          <img
            src={passwordShown ? eye : eyeOff}
            alt="eye"
          />
        </button>
        )}
        rightIconError={isErrorExist && <img src={warning} alt="" />}
      />

      {isErrorExist && (
      <div className={styles.error}>{t(errors.message)}</div>
      )}
      {hintText && (
      <Tippy content={hintText}>
        <img className={styles.tipsImage} src={tips} alt="warning" />
      </Tippy>
      )}
      {secondTitle && <p className={styles.userHint}>{secondTitle}</p>}
    </div>
  );
}

InputWithIconAndLabels.propTypes = {
  type: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  title: PropTypes.string,
  secondTitle: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  passwordShown: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickPassword: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool
  ]),
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  register: PropTypes.func,
  errors: PropTypes.shape({ message: PropTypes.string }),
  name: PropTypes.string,
};

InputWithIconAndLabels.defaultProps = {
  title: '',
  hintText: '',
  secondTitle: '',
  name: '',
  register: () => {},
  defaultValue: '',
  className: '',
  rightIcon: null,
  leftIcon: null,
  errors: {},
  onClickPassword: null,
  passwordShown: false,
  disabled: false
};

export default InputWithIconAndLabels;
