import { useEffect, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import CheckEmailView from './CheckEmailView';
import { sendEmailAsync, onLoad } from '../../store/resetPassword/slice';

function CheckEmail() {
  const email = localStorage.getItem('userEmail');
  const { isLoading, isLoaded } = useSelector((state) => state.resetPassword, shallowEqual);

  const dispatch = useDispatch();
  const reset = useCallback(() => {
    dispatch(sendEmailAsync({ email }));
  }, [email]);

  useEffect(() => {
    if (!isLoading && isLoaded !== null) {
      dispatch(onLoad());
    }
  }, [isLoading, isLoaded]);

  const link = document.createElement('a');
  link.href = `mailto:${email}`;

  return (
    <CheckEmailView
      userEmail={email}
      resend={reset}
      onEmailClick={() => link.click()}
      isLoading={isLoading}
    />
  );
}

export default CheckEmail;
