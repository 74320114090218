import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormHeader from '../../components/FormHeader/FormHeader';
import BackButton from '../../components/BackButton/BackButton';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import logo from '../../assets/images/tickIcon.svg';
import styles from './sass/PasswordResetForm.module.scss';

function PasswordResetView({ onContinueClick, isLoading }) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <FormHeader logo={logo} header={t('passReset')} subHeader={t('hasBeenReset')} />
        <Button onClick={onContinueClick} title={t('continue')} className={styles.button} />
        <BackButton label={t('backToLogin')} path="/login" />
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

PasswordResetView.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PasswordResetView;
