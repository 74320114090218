import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('emailRequired'),
  password: Yup
    .string()
    .required('passwordRequired')
    .max(32, 'passwordToLong')
    .matches(/^(?=.*[A-Z])/, 'uppercaseCharacter')
    .matches(/^(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])/, 'specialCharacter'),
});
export default formSchema;
