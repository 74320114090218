import PropTypes from 'prop-types';
import { t } from 'i18next';
import checked from '../../assets/images/checkedCheckbox.svg';
import checkbox from '../../assets/images/checkboxEmpty.svg';
import styles from './sass/WearItem.module.scss';

function WearItem({ isChecked, handleClick, text }) {
  return (
    <button
      type="button"
      onClick={handleClick}
      className={`${styles.wearItem} ${isChecked ? styles.active : styles.inactive}`}
    >
      <div className={styles.checkboxContainer}>
        <img src={isChecked ? checked : checkbox} className={styles.iconButton} alt="" />
      </div>
      <div>
        <p>{t(text)}</p>
      </div>
    </button>
  );
}

WearItem.propTypes = {
  text: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default WearItem;
