import React from 'react';
import LoginForm from './components/LoginForm/LoginForm';

function LoginView() {
  return (
    <LoginForm />
  );
}

export default LoginView;
