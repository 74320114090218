import { t } from 'i18next';
import PropTypes from 'prop-types';
import Layout from '../../layout/Layout/Layout';
import SmallGallery from './components/SmallGallery/SmallGallery';
import FullscreenGallery from './components/FullscreenGallery/FullScreenGallery';
import InputSearch from '../../components/InputSearch/InputSearch';
import EditWishlistModal from '../../components/EditWishlistModal/EditWishlistModal';
import Loader from '../../components/Loader/Loader';
import styles from './sass/Wishlist.module.scss';

function WishlistView({
  galleryClickHandler,
  itemClickHandler,
  setSearchWishlist,
  setSelected,
  searchWishlist,
  selected,
  fullScreenGalleryTitle,
  galleryList,
  isLoading,
  selectedItem,
  setSelectedItem,
  onWishlistEditSave,
  onItemEditSave,
  deleteWishlistHandler,
  deleteItemHandler,
  onItemSelect,
}) {
  const titles = galleryList?.map((item) => item.name);
  const selectedList = galleryList?.find((item) => item.name === selected);
  const findItems = galleryList?.find((item) => item.name === fullScreenGalleryTitle)?.items;
  const fullscreenGalleryItems = !searchWishlist
    ? findItems : findItems?.filter((i) => i.title.toLowerCase().includes(searchWishlist.toLowerCase()));

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <InputSearch
            onChange={(e) => setSearchWishlist(e.target.value)}
            placeholder={t('searchYourWishList')}
            type="text"
            value={searchWishlist}
          />
          {fullScreenGalleryTitle && <p className={styles.title}>{fullScreenGalleryTitle}</p>}
        </div>
        {!fullScreenGalleryTitle
          ? (
            <div>
              {galleryList && galleryList.length > 0 ? (
                <div className={styles.gallery}>
                  {galleryList.map((box) => (
                    <SmallGallery
                      title={box.name}
                      items={box.items}
                      key={box.name}
                      onClickHandler={() => galleryClickHandler(box.name)}
                      onOptionsClick={() => setSelected(box.name)}
                    />
                  ))}
                </div>
              ) : (
                <div className={styles.warning}>
                  <p className={styles.title}>{t(searchWishlist ? 'noWishlistsFound' : 'noWishlistsYet')}</p>
                </div>
              )}
            </div>
          )
          : (
            <FullscreenGallery
              onOptionsClick={(clicked) => {
                setSelected(fullScreenGalleryTitle);
                setSelectedItem(clicked);
              }}
              onItemClick={itemClickHandler}
              items={fullscreenGalleryItems}
            />
          )}
        {!fullScreenGalleryTitle && selected && (
          <EditWishlistModal
            wishlistEdit
            wishlistName={selected}
            wishlistDescription={selectedList.description}
            onCancelClick={() => setSelected('')}
            onSaveClick={onWishlistEditSave}
            onDeleteClick={() => deleteWishlistHandler(selectedList.id)}
          />
        )}
        {fullScreenGalleryTitle && selected && (
          <EditWishlistModal
            wishlistName={selected}
            names={titles}
            item={selectedItem}
            onCancelClick={() => setSelected('')}
            onSaveClick={onItemEditSave}
            onDeleteClick={(itemId) => deleteItemHandler(selectedList.id, itemId)}
            onItemSelect={onItemSelect}
          />
        )}
      </div>
      {isLoading && <Loader />}
    </Layout>
  );
}

WishlistView.propTypes = {
  galleryClickHandler: PropTypes.func.isRequired,
  itemClickHandler: PropTypes.func.isRequired,
  setSearchWishlist: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  searchWishlist: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  fullScreenGalleryTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  galleryList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  selectedItem: PropTypes.shape({}),
  setSelectedItem: PropTypes.func,
  onWishlistEditSave: PropTypes.func,
  onItemEditSave: PropTypes.func,
  deleteWishlistHandler: PropTypes.func,
  deleteItemHandler: PropTypes.func,
  onItemSelect: PropTypes.func,
};

WishlistView.defaultProps = {
  fullScreenGalleryTitle: '',
  isLoading: false,
  galleryList: null,
  selectedItem: null,
  setSelectedItem: () => {},
  onWishlistEditSave: () => {},
  onItemEditSave: () => {},
  deleteWishlistHandler: () => {},
  deleteItemHandler: () => {},
  onItemSelect: () => {},
};

export default WishlistView;
