import React from 'react';
import LoginView from './LoginView';

function Login() {
  return (
    <LoginView />
  );
}

export default Login;
