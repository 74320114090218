export default {
  login: 'auth/token/',
  convertToken: 'auth/convert-token/',
  refresh: 'auth/jwt/refresh/',
  me: 'auth/me/',
  usersMe: 'users/me/',
  usersDetails: 'users/details/',
  countries: 'countries/list/',
  resetPassword: 'djoser/users/reset_password/',
  resendActivation: 'djoser/users/resend_activation/',
  userActivate: 'djoser/users/activation/',
  updatePassword: 'djoser/users/set_password/',
  setNewPassword: 'djoser/users/reset_password_confirm/',
  signUp: 'users/signup/',
  check: 'users/check/',
  closingType: 'app/sustie_backend/clothing/type/',
  closingStyle: '/app/sustie_backend/clothing/style/',
  checkPassword: 'users/check-password/',
  setStyle: 'users/clothing_style/',
  setClothing: 'users/clothing_type/',
  getStyle: 'clothing/style/',
  getClothing: 'clothing/type/',
  loadWishlists: 'wishlists/lists/',
  getWishlist: 'wishlists/list/',
  getItem: 'wishlists/item/',
};
