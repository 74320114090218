import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import Layout from '../../layout/Layout/Layout';
import StyleItem from '../../components/StyleItem/StyleItem';
import Loader from '../../components/Loader/Loader';
import style from './sass/Style.module.scss';

function StyleView({
  styles,
  checked,
  checkHandler,
  updateHandler,
  isLoading
}) {
  const { t } = useTranslation();

  return (
    <Layout sidebar homenav>
      <div className={style.container}>
        <div className={style.section}>
          <div className={style.head}>
            <h1>{t('yourStyle')}</h1>
            <p>{t('whatDescribes')}</p>
          </div>
          <div className={style.styleContainer}>
            {styles && styles.map((item) => (
              <StyleItem
                id={item.id}
                key={item.id}
                handleClick={checkHandler}
                text={item.name}
                image={item.image}
                isChecked={checked.includes(item.id)}
              />
            ))}
          </div>
          <div className={style.foot}>
            <Button
              title={t('update')}
              onClick={updateHandler}
              className={style.button}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Layout>
  );
}

StyleView.propTypes = {
  styles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string
  })),
  checked: PropTypes.arrayOf(PropTypes.number).isRequired,
  checkHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

StyleView.defaultProps = {
  styles: []
};

export default StyleView;
