import PropTypes from 'prop-types';
import styles from './Collection.module.scss';

function Collection({
  icon, label, text
}) {
  return (
    <div className={styles.collection}>
      <img className={styles.icon} src={icon} alt="" />
      <div>
        <p className={styles.label}>{label}</p>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
}

Collection.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Collection;
