import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button, { BUTTONS_TYPES } from '../../components/Button/Button';
import Sidebar from './components/Sidebar/Sidebar';
import styles from './sass/LayoutAuth.module.scss';

function LayoutAuthView({
  children,
  handleClick,
  titleButtonNext,
  titleButtonBack,
  buttonCentered,
  withSidebar,
  getCurrentStep,
  getIconSidebarItem,
  sidebarItems,
  buttonsSpace,
  isDisable
}) {
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.subContainerWrapper}>
          {withSidebar && (
          <Sidebar
            getIconSidebarItem={getIconSidebarItem}
            getCurrentStep={getCurrentStep}
            sidebarItems={sidebarItems}
          />
          )}
          <div className={styles.subContainer}>
            <div className={styles.content}>{children}</div>
            <div className={`${styles[buttonsSpace]} ${styles.buttons} ${buttonCentered && styles.centeredButtons}`}>
              {titleButtonBack && (
                <Button
                  typeButton={BUTTONS_TYPES.WHITE}
                  onClick={() => navigate(-1)}
                  title={titleButtonBack}
                  className={styles.buttonBack}
                />
              )}

              {titleButtonNext && (
                <Button
                  onClick={handleClick}
                  title={titleButtonNext}
                  disabled={isDisable}
                  className={styles.buttonNext}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LayoutAuthView.propTypes = {
  children: PropTypes.node.isRequired,
  sidebarItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
  })).isRequired,
  getIconSidebarItem: PropTypes.func.isRequired,
  getCurrentStep: PropTypes.func.isRequired,
  buttonsSpace: PropTypes.string.isRequired,
  titleButtonNext: PropTypes.string,
  titleButtonBack: PropTypes.string,
  buttonCentered: PropTypes.bool,
  withSidebar: PropTypes.bool,
  isDisable: PropTypes.bool,
  handleClick: PropTypes.func,
};

LayoutAuthView.defaultProps = {
  titleButtonNext: '',
  titleButtonBack: '',
  buttonCentered: false,
  withSidebar: false,
  isDisable: false,
  handleClick: () => {}
};

export default LayoutAuthView;
