import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import styles from './sass/Select.module.scss';
import IndicatorSeparator from './IndicatorSeparator';
import Control from './Control';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 8,
    padding: '4px 5px',
    border: '1px solid #D0D5DD',
    boxShadow: '0 1px 2px rgba(16, 24, 40, 0.05)',
  })
};

function Select({
  options, value, onChange, label, className
}) {
  return (
    <div className={className}>
      {label && <span className={styles.label}>{label}</span>}
      <ReactSelect
        options={options}
        value={value}
        onChange={onChange}
        styles={customStyles}
        components={{
          IndicatorSeparator,
          Control,
        }}
      />
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.node,
  })).isRequired,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.node,
  }),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

Select.defaultProps = {
  label: null,
  className: '',
  value: {}
};

export default Select;
