import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('firstNameRequired'),
  lastName: Yup.string()
    .required('lastNameRequired'),
  userName: Yup.string()
    .required('userNameRequired')
});
export default formSchema;
