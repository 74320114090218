import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadLists,
  addItem,
  removeItemAsync,
  updateItemAsync,
} from '../../store/wishlist/actions';
import WishlistItemView from './WishlistItemView';

function WishlistItem() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialog, showDialog] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const { wishlistId, itemId } = useParams();
  const { galleryList } = useSelector((state) => state.wishlist);
  const names = galleryList?.map((i) => i.name);
  const [title, id] = itemId.split('id=');
  const wishlist = galleryList?.find((i) => i.name === wishlistId);
  const item = wishlist?.items.find((i) => i.title === title && i.id.toString() === id);

  const [newWishlist, setNewWishlist] = useState(wishlistId);

  useEffect(() => {
    if (galleryList === null) {
      dispatch(loadLists());
    }
  }, []);

  const moveItem = (i, f, t) => {
    dispatch(removeItemAsync({ listId: f.id, itemId: i.id, dispatch }));
    dispatch(addItem({ listId: t.id, item: i }));
    dispatch(updateItemAsync({
      wishlistId: t.id,
      itemId: i.id,
      note: i.note,
      dispatch
    }));
  };

  const onItemSave = (note) => {
    if (wishlistId !== newWishlist) {
      const to = galleryList?.find((i) => i.name === newWishlist);
      moveItem(item, wishlist, to);
      navigate(`/wishlist/${newWishlist}`);
    } else {
      dispatch(updateItemAsync({
        wishlistId: wishlist.id,
        itemId: id,
        note,
        dispatch
      }));
      navigate(`/wishlist/${wishlistId}`);
    }
  };

  return (
    <WishlistItemView
      productPage={item.url}
      productLink={item.url}
      description={item.description}
      title={item.title}
      note={item.note}
      dialog={dialog}
      shareModal={shareModal}
      showDialog={showDialog}
      showModal={setShareModal}
      wishlists={names}
      wishlistIndex={names.indexOf(wishlistId)}
      onSaveClick={onItemSave}
      onCloseClick={() => navigate(`/wishlist/${wishlistId}`)}
      itemImage={item.image_url}
      onItemSelect={(value) => setNewWishlist(galleryList[value].name)}
      onItemDelete={() => {
        const path = galleryList.length > 1 ? `/wishlist/${wishlistId}` : '/wishlist';
        dispatch(removeItemAsync({ listId: wishlist.id, itemId: item.id, dispatch }));
        navigate(path);
      }}
    />
  );
}

export default WishlistItem;
