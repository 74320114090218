import PropTypes from 'prop-types';
import LayoutAuthView from './LayoutAuthView';
import { ButtonSpace, sidebarItems } from '../../constants/general';
import stepIcon from '../../assets/images/stepIcon.svg';
import stepCurrent from '../../assets/images/stepIcondCurrent.svg';
import stepChecked from '../../assets/images/stepIconChecked.svg';

function LayoutAuth({
  children,
  handleClick,
  titleButtonNext,
  titleButtonBack,
  buttonCentered,
  withSidebar,
  buttonsSpace,
  isDisable
}) {
  const getCurrentStep = () => {
    let currentStep;
    switch (window.location.pathname) {
      case '/sign-up-name':
        currentStep = 1;
        break;
      case '/sign-up-gender':
        currentStep = 2;
        break;
      case '/sign-up-age':
        currentStep = 3;
        break;
      case '/sign-up-wear':
        currentStep = 4;
        break;
      case '/sign-up-style':
        currentStep = 5;
        break;
      default:
        currentStep = 0;
    }
    return currentStep;
  };
  const getIconSidebarItem = (step) => {
    const currentStep = getCurrentStep();
    let iconResult = stepIcon;
    if (step === currentStep) {
      iconResult = stepCurrent;
    }
    if (step < currentStep) {
      iconResult = stepChecked;
    }
    return iconResult;
  };
  return (
    <LayoutAuthView
      handleClick={handleClick}
      titleButtonNext={titleButtonNext}
      titleButtonBack={titleButtonBack}
      buttonCentered={buttonCentered}
      withSidebar={withSidebar}
      sidebarItems={sidebarItems}
      getCurrentStep={getCurrentStep}
      getIconSidebarItem={getIconSidebarItem}
      buttonsSpace={buttonsSpace}
      isDisable={isDisable}
    >
      {children}
    </LayoutAuthView>
  );
}

LayoutAuth.propTypes = {
  children: PropTypes.node.isRequired,
  buttonsSpace: PropTypes.string,
  titleButtonNext: PropTypes.string,
  titleButtonBack: PropTypes.string,
  buttonCentered: PropTypes.bool,
  withSidebar: PropTypes.bool,
  isDisable: PropTypes.bool,
  handleClick: PropTypes.func,
};

LayoutAuth.defaultProps = {
  titleButtonNext: '',
  titleButtonBack: '',
  buttonsSpace: ButtonSpace['11'],
  buttonCentered: false,
  withSidebar: false,
  isDisable: false,
  handleClick: () => {}
};

export default LayoutAuth;
