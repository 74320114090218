import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import DragAndDropFiles from '../../components/DragAndDropFiles/DragAndDropFiles';
import Divider from '../../components/Divider/Divider';
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import Profile from './components/Profile';
import Avatar from '../../components/Avatar/Avatar';
import Select from '../../components/Select/Select';
import Layout from '../../layout/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import InputWithIconAndLabels from '../../components/InputWithIconAndLabels/InputWithIconAndLabels';
import { resendEmailAsync } from '../../store/resendActivation/slice';
import { setUserGender } from '../../store/user/action';
import { Ages, Genders } from '../../constants/general';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as MaleIcon } from '../../assets/images/maleOutline.svg';
import { ReactComponent as AgeIcon } from '../../assets/images/age.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/alert.svg';
import avatar from '../../assets/images/defaultAvatar.svg';
import styles from './sass/SettingsMyDetails.module.scss';

function SettingsMyDetailsView({
  country,
  setCountry,
  gender,
  age,
  setAge,
  onSelectFile,
  newAvatar,
  onSave,
  register,
  errors,
  handleSubmit,
  defaultValues,
}) {
  const { t: globalT } = useTranslation();
  const { t } = useTranslation('', { keyPrefix: 'settingMyDetails' });

  const dispatch = useDispatch();
  const { userSettings, isLoading } = useSelector((state) => state.user);
  const { isLoading: isActivationLoading } = useSelector((state) => state.resendActivation);

  const GenderIcon = MaleIcon;

  const ageOptions = Ages.map((
    { title, age: ageLabel }
  ) => ({ label: `${globalT(title)} ${ageLabel}`, value: ageLabel, icon: <AgeIcon /> }));

  const genderOptions = Genders.map(({ title }) => ({
    label: `${globalT(title)}`, value: title, icon: <GenderIcon />
  }));

  return (
    <Layout homenav>
      {(isLoading || isActivationLoading) && <Loader />}
      <form className={styles.myDetails} onSubmit={handleSubmit(onSave)}>
        <div className={styles.containerHeader}>
          <Profile
            firstName={userSettings?.first_name}
            lastName={userSettings?.last_name}
            onSave={handleSubmit(onSave)}
            avatar={userSettings?.avatar || avatar}
            username={userSettings?.username}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.rowFirst}>
            <InputWithIconAndLabels
              title={t('firstName')}
              type="text"
              placeholder=""
              onClickPassword={false}
              register={register}
              name="firstName"
              errors={errors.firstName}
              className={styles.input}
              rightIcon={<EditIcon />}
              defaultValue={defaultValues?.first_name}
            />

            <InputWithIconAndLabels
              onClickPassword={false}
              className={styles.input}
              name="lastName"
              rightIcon={<EditIcon />}
              title={t('lastName')}
              register={register}
              errors={errors?.lastName}
              defaultValue={defaultValues?.last_name}
              placeholder=""
              type="text"
            />
          </div>
          <InputWithIconAndLabels
            onClickPassword={false}
            className={styles.input}
            name="username"
            rightIcon={<EditIcon />}
            leftIcon={<UserIcon />}
            title={t('username')}
            register={register}
            errors={errors?.username}
            defaultValue={defaultValues?.username}
            placeholder=""
            type="text"
          />

          <div className={styles.inputSpace}>
            <InputWithIconAndLabels
              title={t('email')}
              type="text"
              placeholder=""
              onClickPassword={false}
              register={register}
              name="email"
              errors={errors.email}
              className={styles.line}
              rightIcon={<EditIcon />}
              leftIcon={<MailIcon />}
              defaultValue={defaultValues?.email}
            />
          </div>
          <Divider className={styles.line} />

          <div className={cn(styles.dragAndDrop, styles.line)}>
            <Avatar src={newAvatar} alt="new avatar" size="medium" />
            <DragAndDropFiles onDrop={onSelectFile} />
          </div>

          <Divider className={styles.line} />

          <CountrySelect
            onChange={(newValue) => dispatch(setCountry(newValue))}
            value={country}
            className={styles.line}
          />

          <div className={styles.row}>
            <Select
              label={t('gender')}
              onChange={(newValue) => {
                dispatch(setUserGender(newValue.label));
              }}
              options={genderOptions}
              value={genderOptions.filter((item) => item?.label === gender)[0]}
              className={styles.buttonWrapper}
            />
            <Select
              label={t('age')}
              onChange={(newValue) => dispatch(setAge(newValue.label))}
              options={ageOptions}
              value={ageOptions.filter((item) => item?.label === age)[0]}
              className={styles.buttonWrapper}
            />
          </div>
          {!userSettings.is_email_verified && (
          <div className={styles.unverifiedBlock}>
            <AlertIcon />
            <div className={styles.subBlockUnverified}>
              <p className={cn(styles.title, styles.text)}>{globalT('unverifiedEmail')}</p>
              <p className={cn(styles.description, styles.text)}>
                {globalT('accountNotVerified')}
              </p>
              <button
                type="button"
                onClick={() => dispatch(resendEmailAsync({ email: userSettings.email }))}
                className={styles.buttonWarning}
              >
                {globalT('verifyEmail')}
              </button>
            </div>
          </div>
          )}
        </div>
        <input type="submit" className={styles.submit} title="submit" />
      </form>
    </Layout>
  );
}

SettingsMyDetailsView.propTypes = {
  country: PropTypes.string,
  setCountry: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  age: PropTypes.string,
  setAge: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  newAvatar: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.shape({}),
    lastName: PropTypes.shape({}),
    username: PropTypes.shape({}),
    email: PropTypes.shape({
    })
  }),
  defaultValues: PropTypes.shape({
    age: PropTypes.string,
    avatar: PropTypes.string,
    country: PropTypes.number,
    email: PropTypes.string,
    first_name: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.number,
    is_active: PropTypes.bool,
    last_name: PropTypes.string,
    type_of_clothing: PropTypes.arrayOf(PropTypes.number),
    type_of_style: PropTypes.arrayOf(PropTypes.number),
    username: PropTypes.string
  }),
  register: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SettingsMyDetailsView.defaultProps = {
  errors: {},
  country: '',
  age: '',
  newAvatar: null,
  defaultValues: {}
};

export default SettingsMyDetailsView;
