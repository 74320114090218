import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import api from '../../constants/api';

export const initialState = () => ({
  error: null,
  isLoading: false,
  isLoaded: null
});

export const onLoad = createAction('resendActivation/onLoad');

export const resendEmailAsync = createAsyncThunk(
  ' resendActivation/resendEmailAsync',
  async ({ email }) => {
    const { data } = await instance.post(api.resendActivation, { email });
    return data;
  }
);
export const userActivateAsync = createAsyncThunk(
  ' resendActivation/userActivateAsync',
  async ({ uid, token }) => {
    const { data } = await instance.post(api.userActivate, { uid, token });
    return data;
  }
);

export const resendActivationSlice = createSlice({
  name: ' resendActivation',
  initialState: initialState(),
  extraReducers: (builder) => {
    builder
      .addCase(onLoad, initialState)
      .addCase(resendEmailAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendEmailAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = action.payload;
      })
      .addCase(resendEmailAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(userActivateAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userActivateAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = action.payload;
      })
      .addCase(userActivateAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default resendActivationSlice.reducer;
