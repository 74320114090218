import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './sass/Divider.module.scss';

function Divider({ className }) {
  return (
    <div className={cn(styles.divider, className)} />
  );
}

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: '',
};

export default Divider;
