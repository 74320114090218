import PropTypes from 'prop-types';
import styles from './sass/Modal.module.scss';

function Modal({ children }) {
  return <div className={styles.modal}>{children}</div>;
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default Modal;
