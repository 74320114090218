import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import WishListView from './WishlistView';
import {
  loadLists,
  onLoad,
  removeWishlistAsync,
  removeItemAsync,
  updateWishlistAsync,
  updateItemAsync,
  addItem,
} from '../../store/wishlist/actions';

function Wishlist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { wishlistId } = useParams();
  const [searchWishlist, setSearchWishlist] = useState('');
  const [selected, setSelected] = useState('');
  const [selectedItem, setSelectedItem] = useState();
  const [newWishlist, setNewWishlist] = useState(wishlistId);
  const {
    galleryList, isLoading, isLoaded
  } = useSelector((state) => state.wishlist);

  useEffect(() => {
    if (galleryList === null) {
      dispatch(loadLists());
    }
  }, []);

  useEffect(() => {
    if (!isLoading && isLoaded) {
      dispatch(onLoad());
    }
  }, [isLoading, isLoaded]);

  const moveItem = (item, from, to) => {
    dispatch(removeItemAsync({ listId: from.id, itemId: item.id, dispatch }));
    dispatch(addItem({ listId: to.id, item }));
    dispatch(updateItemAsync({
      wishlistId: to.id,
      itemId: item.id,
      note: item.note,
      dispatch
    }));
  };

  const onItemEditSave = (note) => {
    const wishlist = galleryList.find((item) => item.name === selected);
    dispatch(updateItemAsync({
      wishlistId: wishlist.id,
      itemId: selectedItem.id,
      note,
      dispatch
    }));
    if (newWishlist && newWishlist !== wishlistId) {
      const from = galleryList.find((item) => item.name === wishlistId);
      const to = galleryList.find((item) => item.name === newWishlist);
      moveItem(selectedItem, from, to);
    }
  };

  const searchItem = (item) => {
    return item.name.toLowerCase().includes(searchWishlist.toLowerCase());
  };

  return (
    <WishListView
      galleryClickHandler={(title) => navigate(`${location.pathname}/${title}`)}
      itemClickHandler={(title, id) => navigate(`${location.pathname}/${title}id=${id}`)}
      searchWishlist={searchWishlist}
      selected={selected}
      setSearchWishlist={setSearchWishlist}
      setSelected={setSelected}
      fullScreenGalleryTitle={wishlistId}
      galleryList={
        searchWishlist && !wishlistId ? galleryList?.filter(searchItem) : galleryList
      }
      isLoading={isLoading}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      deleteWishlistHandler={(id) => {
        dispatch(removeWishlistAsync({ id, dispatch }));
        setSelected('');
      }}
      deleteItemHandler={(listId, itemId) => {
        dispatch(removeItemAsync({ listId, itemId, dispatch }));
        setSelected('');
      }}
      onWishlistEditSave={(name, description) => {
        const { id } = galleryList.find((item) => item.name === selected);
        dispatch(updateWishlistAsync({
          id, name, description, dispatch
        }));
      }}
      onItemEditSave={onItemEditSave}
      onItemSelect={(value) => {
        setNewWishlist(galleryList[value].name);
      }}
    />
  );
}

export default Wishlist;
