/* eslint-disable */
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import WearView from './WearView';
import { getTypes, getUserTypes, setTypes } from '../../store/clothing/slice';
import { onLoad, setCheckedTypes } from '../../store/clothing/actions';

function Wear() {
  const dispatch = useDispatch();
  const {
    checkedTypes, types, isLoading, isSet
  } = useSelector((state) => state.clothing);

  useEffect(() => {
    if (!isLoading && isSet) {
      dispatch(onLoad);
    }
  }, [isLoading, isSet]);

  useEffect(() => {
    if (!types) dispatch(getTypes());
    if (checkedTypes.length === 0) dispatch(getUserTypes());
  }, []);

  const update = useCallback(() => {
    dispatch(setTypes(checkedTypes));
  }, [checkedTypes]);

  const handleCheck = (id) => {
    const newList = checkedTypes.includes(id)
      ? checkedTypes.filter((item) => item !== id)
      : checkedTypes.concat(id);

    dispatch(setCheckedTypes(newList));
  };

  return (
    <WearView
      Wear={types}
      checked={checkedTypes}
      handleCheck={handleCheck}
      handleClick={update}
      isLoading={isLoading}
    />
  );
}

export default Wear;
