import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useSelector
} from 'react-redux';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import HomeNavigationHeader from '../HomeNavigationHeader/HomeNavigationHeader';
import { SideTabs } from '../../constants/general';
import avatar from '../../assets/images/defaultAvatar.svg';
import style from './sass/Layout.module.scss';

function Layout({ sidebar, homenav, children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const userData = useSelector((storeState) => storeState.user);

  return (
    <>
      <Header avatarImg={userData.userSettings?.avatar || avatar} />
      {homenav && <HomeNavigationHeader />}
      <div className={style.layout}>
        {sidebar && (
        <div className={style.tabs}>
          {SideTabs.map((tab) => (
            <button
              className={`${style.tab} ${location.pathname === tab.route ? style.active : ''}`}
              onClick={() => { navigate(tab.route); }}
              type="button"
              key={tab.id}
            >
              {t(tab.title)}
            </button>
          ))}
        </div>
        )}
        {children}
      </div>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  sidebar: PropTypes.bool,
  homenav: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

Layout.defaultProps = {
  sidebar: false,
  homenav: false
};

export default Layout;
