import { useState, useEffect } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Layout from '../../layout/LayoutAuth/LayoutAuth';
import GenderItem from './components/GenderItem';
import { setUserData } from '../../store/auth/actions';
import { getUserSettingsAsync, updateUserSettingsAsync } from '../../store/user/slice';
import { Genders } from '../../constants/general';
import styles from './sass/GenderRegistration.module.scss';

function GenderRegistrationView() {
  const navigate = useNavigate();

  const [gender, setGender] = useState('');

  const dispatch = useDispatch();
  const userData = useSelector((storeState) => storeState.auth);
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);

  const onSubmit = () => {
    if (accessToken) {
      dispatch(updateUserSettingsAsync({
        gender: t(gender)
      }));
      navigate('/sign-up-age');
    } else {
      dispatch(setUserData({
        ...userData.dataUser,
        gender: t(gender)
      }));
      navigate('/sign-up-age');
    }
  };

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserSettingsAsync({ dispatch })).then((data) => {
        setGender(data?.payload?.gender.toLowerCase());
      });
    }
  }, []);

  return (
    <Layout
      titleButtonNext={t('next')}
      titleButtonBack={t('back')}
      handleClick={onSubmit}
      withSidebar
      isDisable={!gender}
    >
      <div className={styles.genderContainer}>
        {Genders.map(({ title, icon }) => (
          <GenderItem
            key={title}
            title={t(title)}
            icon={icon}
            isActive={gender === title}
            onClick={() => setGender(title)}
          />
        ))}
      </div>
    </Layout>
  );
}

export default GenderRegistrationView;
