import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HomeNavPages } from '../../constants/general';
import style from './sass/HomeNavigationHeader.module.scss';

function HomeNavigationHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <header className={style.header}>
      <div className={style.container}>
        <div className={style.content}>
          {HomeNavPages.map((page) => {
            return (
              <button
                key={page.id}
                type="button"
                onClick={() => {
                  navigate(page.routes[0]);
                }}
                className={`${style.navElement} ${page.routes.includes(location.pathname) ? style.active : ''}`}
              >
                {t(page.title)}
              </button>
            );
          })}
        </div>
      </div>
    </header>
  );
}

export default HomeNavigationHeader;
