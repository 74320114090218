import { createSlice, current } from '@reduxjs/toolkit';
import {
  addWishlist,
  removeWishlist,
  removeItem,
  addItem,
  onLoad,
  updateWishlistName,
  updateWishlistDescription,
  updateItemNote,
  loadLists,
  addWishlistAsync,
  removeWishlistAsync,
  removeItemAsync,
  updateWishlistAsync,
  updateItemAsync,
} from './actions';

const compareIds = (id1, id2) => id1.toString() === id2.toString();

const initialState = {
  galleryList: null,
  isLoading: false,
  isLoaded: false,
  errors: null
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(onLoad, (state) => {
        state.isLoaded = false;
      })
      .addCase(loadLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.galleryList = action.payload.data;
      })
      .addCase(loadLists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadLists.rejected, (state, action) => {
        state.isLoaded = false;
        state.errors = action.payload;
      })
      .addCase(addWishlist, (state, action) => {
        state.galleryList.push(action.payload);
      })
      .addCase(removeWishlist, (state, action) => {
        const index = state.galleryList.findIndex((item) => compareIds(item.id, action.payload));
        state.galleryList.splice(index, 1);
      })
      .addCase(removeItem, (state, action) => {
        const { listId, itemId } = action.payload;
        const list = state.galleryList.find((item) => compareIds(item.id, listId));
        const index = list.items.findIndex((item) => compareIds(item.id, itemId));
        list.items.splice(index, 1);
      })
      .addCase(addItem, (state, action) => {
        const { listId, item } = action.payload;
        return {
          ...state,
          galleryList: current(state).galleryList.map((list) => {
            if (list.id === listId) return { ...list, items: [...list.items, item] };
            return list;
          })
        };
      })
      .addCase(updateWishlistName, (state, action) => {
        const { id, name } = action.payload;
        const wishlist = state.galleryList.find((item) => compareIds(item.id, id));
        wishlist.name = name;
      })
      .addCase(updateWishlistDescription, (state, action) => {
        const { id, description } = action.payload;
        const wishlist = state.galleryList.find((item) => compareIds(item.id, id));
        wishlist.description = description;
      })
      .addCase(updateItemNote, (state, action) => {
        const { wishlistId, itemId, note } = action.payload;
        const wishlist = state.galleryList.find((item) => compareIds(item.id, wishlistId));
        wishlist.items.find((item) => compareIds(item.id, itemId))
          .note = note;
      })
      .addCase(addWishlistAsync.pending, (state) => { state.isLoading = true; })
      .addCase(addWishlistAsync.fulfilled, (state) => { state.isLoading = false; })
      .addCase(addWishlistAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(removeWishlistAsync.pending, (state) => { state.isLoading = true; })
      .addCase(removeWishlistAsync.fulfilled, (state) => { state.isLoading = false; })
      .addCase(removeWishlistAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(removeItemAsync.pending, (state) => { state.isLoading = true; })
      .addCase(removeItemAsync.fulfilled, (state) => { state.isLoading = false; })
      .addCase(removeItemAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(updateWishlistAsync.pending, (state) => { state.isLoading = true; })
      .addCase(updateWishlistAsync.fulfilled, (state) => { state.isLoading = false; })
      .addCase(updateWishlistAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(updateItemAsync.pending, (state) => { state.isLoading = true; })
      .addCase(updateItemAsync.fulfilled, (state) => { state.isLoading = false; })
      .addCase(updateItemAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      });
  }
});

export default wishlistSlice.reducer;
