import { t } from 'i18next';
import PropTypes from 'prop-types';
import AgeItem from './components/AgeItem';
import Layout from '../../layout/LayoutAuth/LayoutAuth';
import { Ages, ButtonSpace } from '../../constants/general';
import styles from './sass/AgeRegistration.module.scss';

function AgeRegistrationView({ onSubmit, age, setAge }) {
  return (
    <Layout
      titleButtonNext={t('next')}
      titleButtonBack={t('back')}
      buttonsSpace={ButtonSpace['13']}
      handleClick={onSubmit}
      withSidebar
      isDisable={!age}
    >
      <div className={styles.ageContainer}>
        {Ages.map((item) => (
          <AgeItem
            key={item.age}
            handleClick={() => setAge(item.age)}
            text={item.title}
            isChecked={age === item.age}
            numberRange={item.age}
          />
        ))}
      </div>
    </Layout>
  );
}
AgeRegistrationView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  age: PropTypes.string.isRequired,
  setAge: PropTypes.func.isRequired
};
export default AgeRegistrationView;
