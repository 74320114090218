import { useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { logout } from '../../store/auth/actions';
import { feedBackLink } from '../../constants/general';
import placeholder from '../../assets/images/defaultAvatar.svg';
import settingsIcon from '../../assets/images/gearIconBlack.svg';
import settingsIconActive from '../../assets/images/gearIconActive.svg';
import logo from '../../assets/images/logotxt.svg';
import exit from '../../assets/images/shareIcon.svg';
import style from './sass/Header.module.scss';

function Header({ avatarImg }) {
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);
  const { userSettings } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSettingsPage = pathname.includes('/settings/');

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, []);

  return (
    <header className={style.header}>
      <div className={style.container}>
        <div className={style.leftHalf}>
          <button className={style.logo} type="button" onClick={() => { navigate('/'); }}>
            <img src={logo} alt="logo" />
          </button>
          <div className={style.navigation}>
            <Link
              to="/"
              className={`${style.linkStyle} ${pathname === '/' ? style.active : ''}`}
            >
              <p>{t('home')}</p>
            </Link>
            <Link
              to="/wishlist"
              className={`${style.linkStyle} ${pathname.includes('/wishlist') ? style.active : ''}`}
            >
              <p>{t('wishlist')}</p>
            </Link>
            <a className={style.linkStyle} target="_blank" href={feedBackLink} rel="noreferrer">
              <p>{t('feedback')}</p>
            </a>
          </div>
        </div>
        <div className={style.rightHalf}>
          {userSettings?.is_email_verified === false
            && <span className={style.warning}>{t('unverified')}</span>}
          <button
            type="button"
            className={cn(style.buttonWrapper, isSettingsPage && style.activeButton)}
            onClick={() => navigate('/settings/my-details')}
          >
            <img
              className={style.image}
              src={isSettingsPage ? settingsIconActive : settingsIcon}
              alt="settings"
            />
          </button>
          <button type="button" onClick={() => navigate('/settings/my-details')} className={style.buttonWrapper}>
            <img className={style.avatarImage} src={avatarImg} alt="avatar" />
          </button>

          {accessToken && isSettingsPage && (
            <button type="button" onClick={onLogout} className={style.logout}>
              <img src={exit} alt="" className={style.icon} />
              <span className={style.label}>{t('logout')}</span>
            </button>
          )}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  avatarImg: PropTypes.string
};

Header.defaultProps = {
  avatarImg: placeholder
};

export default Header;
