import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button, { BUTTONS_TYPES } from '../Button/Button';
import deleteImg from '../../assets/images/deleteIcon.svg';
import closeImg from '../../assets/images/closeButton.svg';
import styles from './sass/Modal.module.scss';

function DeleteWishlistModal({
  onCancelClick, onDeleteClick, title, message
}) {
  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <img src={deleteImg} alt="bin" />
        <button className={styles.close} type="button" onClick={onCancelClick}>
          <img src={closeImg} alt="X" />
        </button>
      </div>
      <h1 className={styles.bold}>{title}</h1>
      <p>{message}</p>
      <div className={styles.head}>
        <Button
          title={t('cancel')}
          onClick={onCancelClick}
          className={styles.cancel}
          typeButton={BUTTONS_TYPES.WHITE}
        />
        <Button
          title={t('delete')}
          onClick={onDeleteClick}
          typeButton={BUTTONS_TYPES.RED}
          className={styles.delete}
        />
      </div>
    </div>
  );
}

DeleteWishlistModal.propTypes = {
  onCancelClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

DeleteWishlistModal.defaultProps = {
  onCancelClick: () => {},
  onDeleteClick: () => {}
};

export default DeleteWishlistModal;
