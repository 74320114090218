import PropTypes from 'prop-types';
import icon from '../../assets/images/miniArrow.svg';
import styles from './sass/DropdownInput.module.scss';

function DropdownInput({
  title, items, value, onItemSelect
}) {
  return (
    <div className={styles.inputContainer}>
      <p className={styles.label}>{title}</p>
      <select
        className={styles.inputRect}
        label={title}
        defaultValue={value}
        onChange={(e) => onItemSelect(e.target.value)}
      >
        {items.map((item) => {
          return (
            <option value={items.indexOf(item)} key={item}>{item}</option>
          );
        })}
      </select>
      <img className={styles.icon} src={icon} alt="v" />
    </div>
  );
}

DropdownInput.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  onItemSelect: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.string).isRequired
};

DropdownInput.defaultProps = {
  value: 0,
  title: '',
  onItemSelect: () => {}
};

export default DropdownInput;
