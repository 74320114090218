import { version } from '../../constants/general';
import styles from './sass/Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.versionContainer}>
          <h4>{version}</h4>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
