import loader from '../../assets/images/loader.png';
import styles from './sass/Loader.module.scss';

export default function Loader() {
  return (
    <div className={styles.loader}>
      <img
        src={loader}
        className={styles.icon}
        alt="loader"
      />
      <span className={styles.label}>Loading...</span>
    </div>
  );
}
