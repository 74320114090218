import PropTypes from 'prop-types';
import cn from 'classnames';
import loader from '../../assets/images/loader.png';
import styles from './sass/Avatar.module.scss';

function Avatar({
  size, src, alt, sizeLoader
}) {
  return (src ? (
    <img
      src={src}
      alt={alt}
      className={cn(styles.avatar, styles[size])}
    />
  ) : (
    <img
      src={loader}
      className={cn(styles.icon, styles[sizeLoader])}
      alt="loader"
    />
  )
  );
}

Avatar.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sizeLoader: PropTypes.oneOf(['small', 'medium', 'large']),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
  size: 'small',
  sizeLoader: 'small',
};

export default Avatar;
