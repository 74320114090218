import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WearItem from '../../components/WearItem/WearItem';
import Button from '../../components/Button/Button';
import Layout from '../../layout/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import style from './sass/Wear.module.scss';

function WearView({
  Wear,
  checked,
  handleCheck,
  handleClick,
  isLoading
}) {
  const { t } = useTranslation();

  return (
    <Layout sidebar homenav>
      <div className={style.container}>
        <div className={style.section}>
          <div className={style.head}>
            <h1>{t('whatYouWear')}</h1>
            <p>{t('whatClothing')}</p>
          </div>
          <div className={style.group}>
            {Wear && Wear.map((item) => (
              <WearItem
                id={item.id}
                key={item.id}
                handleClick={() => handleCheck(item.id)}
                text={item.name}
                isChecked={checked.includes(item.id)}
              />
            ))}
          </div>
          <div className={style.foot}>
            <Button
              title={t('update')}
              onClick={handleClick}
              className={style.button}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Layout>
  );
}

WearView.propTypes = {
  Wear: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  checked: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

WearView.defaultProps = {
  Wear: []
};

export default WearView;
