import PropTypes from 'prop-types';
import InputWithIconAndLabels from '../InputWithIconAndLabels/InputWithIconAndLabels';
import style from './sass/InputWithLabels.module.scss';

function InputWithLabels({
  label,
  tip,
  type,
  value,
  name,
  placeholder,
  register,
  errors,
  disabled
}) {
  return (
    <div className={style.inputElement}>
      {label && <span className={style.labelLeft}>{label}</span>}
      <div className={style.mainInput}>
        <InputWithIconAndLabels
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          register={register}
          errors={errors}
          onClickPassword={null}
          disabled={disabled}
        />
        {tip && <span className={style.labelBottom}>{tip}</span>}
      </div>
    </div>
  );
}

InputWithLabels.propTypes = {
  label: PropTypes.string,
  tip: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  errors: PropTypes.shape({})
};

InputWithLabels.defaultProps = {
  label: '',
  tip: '',
  register: () => {},
  disabled: false,
  errors: {}
};

export default InputWithLabels;
