import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUserClosingTypeAsync } from '../../store/auth/slice';
import { setUserData } from '../../store/auth/actions';
import WearRegistrationView from './WearRegistrationView';
import { getUserSettingsAsync } from '../../store/user/slice';
import { setTypes } from '../../store/clothing/slice';

function WearRegistration() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState([]);

  const dispatch = useDispatch();
  const userData = useSelector((storeState) => storeState.auth);
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);

  const handleCheck = (id) => {
    const newList = checked.includes(id)
      ? checked.filter((item) => item !== id)
      : checked.concat(id);

    setChecked(newList);
  };

  useEffect(() => {
    dispatch(getUserClosingTypeAsync({ dispatch }));
    if (accessToken) {
      dispatch(getUserSettingsAsync({ dispatch })).then((data) => {
        setChecked(data.payload.type_of_clothing);
      });
    }
  }, []);

  const onSubmit = () => {
    if (accessToken) {
      dispatch(setTypes(checked));
      navigate('/sign-up-style');
    } else {
      dispatch(setUserData({
        ...userData.dataUser,
        typeOfClothing: checked.map((id) => parseInt(id))
      }));
      navigate('/sign-up-style');
    }
  };

  return (
    <WearRegistrationView
      userData={userData}
      checked={checked}
      handleCheck={handleCheck}
      onSubmit={onSubmit}
    />
  );
}

export default WearRegistration;
