import React from 'react';
import PropTypes from 'prop-types';
import featuredIcon from '../../../../assets/images/featuredIcon.svg';
import styles from './sass/FullScreenGallery.module.scss';

function FullScreenGallery({
  onOptionsClick, onItemClick, items
}) {
  return (
    <div className={styles.imagesWrapper}>
      {items && items.map((item) => (
        <div className={styles.imageContainer} key={item.id}>
          <button
            type="button"
            className={styles.imageButton}
            onClick={() => onItemClick(item.title, item.id)}
          >
            <img className={styles.image} src={item.image_url} alt="" />
          </button>
          <button type="button" className={styles.optionsButton} onClick={() => onOptionsClick(item)}>
            <img className={styles.hoverIcon} src={featuredIcon} alt="" />
          </button>
        </div>
      ))}
    </div>
  );
}
FullScreenGallery.propTypes = {
  onOptionsClick: PropTypes.func,
  onItemClick: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    photo: PropTypes.string
  })),
};

FullScreenGallery.defaultProps = {
  onOptionsClick: () => {},
  onItemClick: () => {},
  items: null
};

export default FullScreenGallery;
