import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import PasswordResetView from './PasswordResetView';
import { loginAsync } from '../../store/auth/slice';

function PasswordReset() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth.tokens, shallowEqual);
  const { isLoading } = useSelector((state) => state.auth, shallowEqual);
  const email = localStorage.getItem('userEmail');
  const password = localStorage.getItem('userPassword');

  const login = useCallback(() => {
    dispatch(loginAsync({ email, password }));
  }, [email, password]);

  useEffect(() => {
    if (accessToken) {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('userPassword');
      navigate('/wishlist');
    }
  }, [accessToken]);

  return <PasswordResetView onContinueClick={login} isLoading={isLoading} />;
}

export default PasswordReset;
