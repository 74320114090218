import PropTypes from 'prop-types';
import searchIcon from '../../assets/images/searchIcon.svg';
import styles from './sass/InputSearch.module.scss';

function InputSearch({
  onChange, placeholder, value, type,
}) {
  return (
    <form className={styles.inputContainer}>
      <div className={styles.iconContainer}>
        <img
          className={styles.tipsImage}
          src={searchIcon}
          alt="searchIcon"
        />
      </div>
      <input
        type={type}
        value={value}
        name="text"
        className={styles.searchInput}
        placeholder={placeholder}
        onChange={onChange}
      />
    </form>
  );
}

InputSearch.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputSearch;
