import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import arrow from '../../assets/images/arrow.svg';
import styles from './sass/BackButton.module.scss';

function BackButton({ path, label }) {
  return (
    <div className={styles.button}>
      <div className={styles.arrow}>
        <img src={arrow} alt="go back" className={styles.image} />
      </div>
      <Link to={path} className={styles.string}>{label}</Link>
    </div>
  );
}

BackButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default BackButton;
