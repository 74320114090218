import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import api from '../../constants/api';
import {
  onLoad,
  setCheckedTypes,
  setCheckedStyles
} from './actions';

export const initialState = () => ({
  types: null,
  styles: null,
  checkedTypes: [],
  checkedStyles: [],
  error: null,
  isLoading: false,
  isSet: null
});

export const getStyles = createAsyncThunk(
  'clothing/getStyles',
  async () => {
    const { data } = await instance.get(api.getStyle);
    return data;
  }
);

export const getTypes = createAsyncThunk(
  'clothing/getTypes',
  async () => {
    const { data } = await instance.get(api.getClothing);
    return data;
  }
);
export const getUserStyles = createAsyncThunk(
  'clothing/getUserStyles',
  async () => {
    const { data } = await instance.get(api.usersMe);
    return data;
  }
);

export const getUserTypes = createAsyncThunk(
  'clothing/getUserTypes',
  async () => {
    const { data } = await instance.get(api.usersMe);
    return data;
  }
);

export const setStyles = createAsyncThunk(
  'clothing/setStyles',
  async (styles) => {
    const { data } = await instance.patch(api.setStyle, { type_of_style: styles });
    return data;
  }
);

export const setTypes = createAsyncThunk(
  'clothing/setTypes',
  async (types) => {
    const { data } = await instance.patch(api.setClothing, { type_of_clothing: types });
    return data;
  }
);

export const clothingSlice = createSlice({
  name: 'clothing',
  initialState: initialState(),
  extraReducers: (builder) => {
    builder
      .addCase(setCheckedTypes, (state, action) => {
        state.checkedTypes = action.payload;
      })
      .addCase(setCheckedStyles, (state, action) => {
        state.checkedStyles = action.payload;
      })
      .addCase(onLoad, (state) => {
        state.isSet = null;
      })
      .addCase(getStyles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStyles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.styles = action.payload;
      })
      .addCase(getStyles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.types = action.payload;
      })
      .addCase(getTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setStyles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setStyles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSet = action.payload;
      })
      .addCase(setStyles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSet = action.payload;
      })
      .addCase(setTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getUserStyles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserStyles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkedStyles = action.payload.type_of_style;
      })
      .addCase(getUserStyles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getUserTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkedTypes = action.payload.type_of_clothing;
      })
      .addCase(getUserTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default clothingSlice.reducer;
