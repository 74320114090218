import { useEffect, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import ForgotPasswordView from './ForgotPasswordView';
import formSchema from './schema';
import { onLoad, sendEmailAsync } from '../../store/resetPassword/slice';
import { setUserCheckPassword } from '../../store/auth/slice';

function ForgotPassword() {
  const navigate = useNavigate();
  const { isLoading, isLoaded } = useSelector((state) => state.resetPassword, shallowEqual);

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  };
  const {
    register, handleSubmit, formState: { errors }, getValues, setError
  } = useForm(formOptions);

  const dispatch = useDispatch();
  const reset = useCallback(() => {
    dispatch(setUserCheckPassword({ email: getValues('email') }))
      .then((response) => {
        if (response.payload.own_password) {
          dispatch(sendEmailAsync({ email: getValues('email') }));
        } else {
          setError('email', {
            type: 'server',
            message: `${t('sorryYouRegisteredByGoogle')}`,
          });
        }
      });
  }, [getValues('email')]);

  useEffect(() => {
    if (!isLoading && isLoaded !== null) {
      localStorage.setItem('userEmail', getValues('email'));
      dispatch(onLoad());
      navigate('/check-email');
    }
  }, [isLoading, isLoaded]);

  return (
    <ForgotPasswordView
      onFormSubmit={handleSubmit(reset)}
      isLoading={isLoading}
      errorMessage={errors.email ? errors.email.message : ''}
      register={register}
    />
  );
}

export default ForgotPassword;
