import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import api from '../../constants/api';

export const addWishlist = createAction('wishlist/addWishlist');
export const removeWishlist = createAction('wishlist/removeWishlist');
export const removeItem = createAction('wishlist/removeItem');
export const addItem = createAction('wishlist/addItem');
export const onLoad = createAction('wishlist/onLoad');
export const updateWishlistName = createAction('wishlist/updateWishlistName');
export const updateWishlistDescription = createAction('wishlist/updateWishlisDescription');
export const updateItemNote = createAction('wishlist/updateItemNote');

export const loadLists = createAsyncThunk(
  'wishlist/loadLists',
  async () => {
    const { data } = await instance.get(api.loadWishlists);
    return data;
  }
);

export const addWishlistAsync = createAsyncThunk(
  'wishlist/addWishlistAsync',
  async ({ list, dispatch }) => {
    const { data } = await instance.post(api.loadWishlists, list.name);
    dispatch(addWishlist(list));
    return data;
  }
);

export const addItemAsync = createAsyncThunk(
  'wishlist/addItemAsync',
  async ({ listId, item, dispatch }) => {
    // const { data } = await instance.post(`${api.getItem}/`, item);
    dispatch(addItem({ listId, item }));
  }
);

export const removeWishlistAsync = createAsyncThunk(
  'wishlist/removeWishlistAsync',
  async ({ id, dispatch }) => {
    const { data } = await instance.delete(`${api.getWishlist}${id}/`);
    dispatch(removeWishlist(id));
    return data;
  }
);

export const removeItemAsync = createAsyncThunk(
  'wishlist/removeItemAsync',
  async ({ listId, itemId, dispatch }) => {
    const { data } = await instance.delete(`${api.getItem}${itemId}/`);
    dispatch(removeItem({ listId, itemId }));
    return data;
  }
);

export const updateWishlistAsync = createAsyncThunk(
  'wishlist/updateWishlistAsync',
  async ({
    id, name, description, dispatch
  }) => {
    const { data } = await instance.patch(`${api.getWishlist}${id}/`, { name, description });
    dispatch(updateWishlistName({ id, name }));
    dispatch(updateWishlistDescription({ id, description }));
    return data;
  }
);

export const updateItemAsync = createAsyncThunk(
  'wishlist/updateItemAsync',
  async ({
    wishlistId, itemId, note, dispatch
  }) => {
    const { data } = await instance.patch(`${api.getItem}${itemId}/`, { wishlist: [wishlistId], note });
    dispatch(updateItemNote({ wishlistId, itemId, note }));
    return data;
  }
);
