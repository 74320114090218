import { useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const ACTIONS = {
  TOKENS: 'TOKENS',
  LOGOUT: 'LOGOUT',
};

const useChromeExtension = () => {
  const { accessToken, refreshToken } = useSelector((state) => state.auth.tokens, shallowEqual);
  const ext = useRef(null);

  const sendTokens = useCallback((access, refresh) => {
    if (ext.current && access && refresh) {
      ext.current.postMessage({
        action: ACTIONS.TOKENS,
        data: {
          accessToken: access,
          refreshToken: refresh
        }
      });
    } else if (ext.current && !access && !refresh) {
      ext.current.postMessage({
        action: ACTIONS.LOGOUT,
      });
    }
  }, []);

  useEffect(() => {
    ext.current = window.chrome?.runtime?.connect(process.env.REACT_APP_EXT_ID);
    return () => {
      ext.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    sendTokens(accessToken, refreshToken);
  }, [accessToken, refreshToken]);
};

export default useChromeExtension;
