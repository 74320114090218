import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button, { BUTTONS_TYPES } from '../Button/Button';
import DropdownInput from '../DropdownInput/DropdownInput';
import DeleteWishlistModal from '../DeleteWishlistModal/DeleteWishlistModal';
import Modal from '../../layout/Modal/Modal';
import styles from './sass/Modal.module.scss';

function EditWishlistModal({
  onCancelClick,
  onSaveClick,
  onDeleteClick,
  wishlistName,
  wishlistEdit,
  wishlistDescription,
  names,
  item,
  onItemSelect,
}) {
  const { t } = useTranslation();
  const [dialog, showDialog] = useState(false);
  const [nameEdit, setNameEdit] = useState(wishlistName);
  const edit = useRef();

  return (
    <Modal>
      {dialog
        ? (
          <DeleteWishlistModal
            title={wishlistEdit ? t('deleteWishlist') : t('deleteItem')}
            message={`${wishlistEdit
              ? t('shureDeleteWishlist')
              : t('shureDeleteItem')} ${t('actionCantBeUndone')}`}
            onCancelClick={() => showDialog(false)}
            onDeleteClick={() => onDeleteClick(item.id)}
          />
        )
        : (
          <div className={styles.card}>
            <div className={styles.subContainer}>
              <h1 className={styles.bold}>{t('editYourWishlist')}</h1>
              {wishlistEdit
                ? (
                  <div>
                    <p className={styles.label}>{t('name')}</p>
                    <input
                      type="text"
                      defaultValue={nameEdit}
                      placeholder=""
                      className={`${styles.area} ${styles.nameInput}`}
                      onChange={(e) => setNameEdit(e.target.value)}
                    />
                    <p className={styles.label}>{t('description')}</p>
                    <textarea
                      className={styles.area}
                      placeholder={t('whatIsYourWishlist')}
                      defaultValue={wishlistDescription}
                      ref={edit}
                    />
                  </div>
                )
                : (
                  <div>
                    <DropdownInput
                      title={t('wishlist')}
                      items={names}
                      value={names.indexOf(wishlistName)}
                      onItemSelect={onItemSelect}
                    />
                    <p className={styles.label}>{t('noteToSelf')}</p>
                    <textarea
                      className={styles.area}
                      placeholder={t('addPrivateNote')}
                      defaultValue={item.note}
                      ref={edit}
                    />
                  </div>
                )}
            </div>
            <div className={styles.foot}>
              <Button
                title={t('delete')}
                onClick={() => showDialog(true)}
                typeButton={BUTTONS_TYPES.RED}
                className={styles.delete}
              />
              <div className={styles.box}>
                <Button
                  title={t('cancel')}
                  onClick={onCancelClick}
                  typeButton={BUTTONS_TYPES.GRAY}
                  className={styles.cancel}
                />
                <Button
                  title={t('save')}
                  className={styles.save}
                  onClick={() => {
                    if (wishlistEdit) onSaveClick(nameEdit, edit.current.value);
                    else onSaveClick(edit.current.value);
                    onCancelClick();
                  }}
                />
              </div>
            </div>
          </div>
        )}
    </Modal>
  );
}

EditWishlistModal.propTypes = {
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  wishlistName: PropTypes.string,
  wishlistEdit: PropTypes.bool,
  wishlistDescription: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    note: PropTypes.string,
  }),
  onItemSelect: PropTypes.func,
};

EditWishlistModal.defaultProps = {
  onCancelClick: () => {},
  onSaveClick: () => {},
  onDeleteClick: () => {},
  wishlistName: '',
  wishlistEdit: false,
  wishlistDescription: '',
  names: [],
  item: { id: 0, note: '' },
  onItemSelect: () => {},
};

export default EditWishlistModal;
