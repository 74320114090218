import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from '../../../components/Avatar/Avatar';
import Button from '../../../components/Button/Button';
import styles from '../sass/Profile.module.scss';

function Profile({
  avatar, firstName, lastName, username, onSave
}) {
  const { t } = useTranslation('', { keyPrefix: 'settingMyDetails' });
  return (
    <div className={styles.profile}>
      <Avatar src={avatar} alt={username} size="large" />
      <div className={styles.name}>
        <h1 className={styles.personName}>
          {firstName}
          {' '}
          {lastName}
        </h1>
        <p className={styles.username}>
          @
          {username}
        </p>
      </div>
      <div className={styles.buttons}>

        <Button onClick={onSave} title={t('save')} />
      </div>

    </div>
  );
}

Profile.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  username: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  avatar: '',
  firstName: '',
  lastName: '',
  username: '',
};

export default Profile;
