import PropTypes from 'prop-types';
import { t } from 'i18next';
import logo from '../../../../assets/images/logo.svg';
import styles from '../../sass/LayoutAuth.module.scss';

function Sidebar({ sidebarItems, getIconSidebarItem, getCurrentStep }) {
  return (
    <div className={styles.sidebar}>
      <img src={logo} className={styles.logo} alt="" />
      {sidebarItems.map(({ title, subtitle, step }) => (
        <div key={title} className={styles.sidebarItem}>
          <div>
            <img
              src={getIconSidebarItem(step)}
              className={`${step === getCurrentStep()
                ? styles.sidebarItemActive
                : styles.sidebarItemNotActive}`}
              alt=""
            />
          </div>
          <div className={styles.sidebarTextContainer}>
            <p className={styles.sidebarTitle}>{t(title)}</p>
            <p className={styles.sidebarSubTitle}>{t(subtitle)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
Sidebar.propTypes = {
  sidebarItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
  })).isRequired,
  getIconSidebarItem: PropTypes.func.isRequired,
  getCurrentStep: PropTypes.func.isRequired,
};

export default Sidebar;
